<template>
    <div v-show="isLoading" class="row" :style="isMobile ? 'width: 100%' : 'width: 0'">
        <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="loader-container">
                <div class="loader-content">
                    <svg class="lds-spinner" width="200px" height="200px" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"
                        style="background: none;">
                        <g transform="rotate(0 50 50)">
                            <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12"
                                :style="{ fill: mergedStyle.backgroundColor }">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                                    begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(30 50 50)">
                            <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12"
                                :style="{ fill: mergedStyle.backgroundColor }">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                                    begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(60 50 50)">
                            <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12"
                                :style="{ fill: mergedStyle.backgroundColor }">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(90 50 50)">
                            <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12"
                                :style="{ fill: mergedStyle.backgroundColor }">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                                    begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(120 50 50)">
                            <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12"
                                :style="{ fill: mergedStyle.backgroundColor }">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                                    begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(150 50 50)">
                            <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12"
                                :style="{ fill: mergedStyle.backgroundColor }">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(180 50 50)">
                            <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12"
                                :style="{ fill: mergedStyle.backgroundColor }">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                                    begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(210 50 50)">
                            <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12"
                                :style="{ fill: mergedStyle.backgroundColor }">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                                    begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(240 50 50)">
                            <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12"
                                :style="{ fill: mergedStyle.backgroundColor }">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(270 50 50)">
                            <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12"
                                :style="{ fill: mergedStyle.backgroundColor }">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                                    begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(300 50 50)">
                            <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12"
                                :style="{ fill: mergedStyle.backgroundColor }">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s"
                                    begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                        <g transform="rotate(330 50 50)">
                            <rect x="47" y="24" rx="9.4" ry="4.8" width="6" height="12"
                                :style="{ fill: mergedStyle.backgroundColor }">
                                <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s"
                                    repeatCount="indefinite"></animate>
                            </rect>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        mergedStyle: {
            type: Object,
            default: () => ({}),
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isMobile: {
            type: Boolean
        }
    }
}
</script>

<style scoped>
.row {
    display: flex;
    flex-wrap: wrap;
    z-index: 99999;
}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999;
}

.loader-content {
    text-align: center;
}
</style>