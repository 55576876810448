<template>
  <div style="height: 100%; width: 100%;" id="app">
    <YurekaiNeoMapBaseSearch marker-clicked="getInfoWindowContent" apikey="AIzaSyDtSCVEZsZzdPCXCG7Vx1Ein4OAH5aCDjc"
    version="weekly" libraries="places,geometry,visualization,drawing,streetView" agentid="" lang="us">></YurekaiNeoMapBaseSearch>
  </div>
</template>

<script>
import YurekaiNeoMapBaseSearch from './components/YurekaiNeoMapBaseSearch.vue';
import { loadGoogleApi } from './googleLoader';

export default {
  name: 'App',
  components: {
    YurekaiNeoMapBaseSearch
  },
  created() {
    loadGoogleApi().then(() => {
    }).catch((error) => {
      console.error('Error loading Google API', error);
    });
  },
}
</script>

<style>
input, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

body {
  overflow: hidden;
}

.offcanvas  {
  width: 100vw;;
}

.box {
  width: 100vh;
}

.gmnoprint.gm-style-mtc-bbw {
  gap: 10px !important;
}

.gm-svpc {
  border-radius: 10px !important;
}

.gm-control-active.gm-fullscreen-control {
  border-radius: 10px !important;
}

.pac-container {
  z-index: 1058 !important;
}

.exCVRN-size-observer-view {
  z-index: -10 !important;
}

.gm-style-mtc button {
  border-radius: 10px !important;
  border-start-end-radius: 10px !important;
  border-end-end-radius: 10px !important;
  border-start-start-radius: 10px !important;
  border-end-start-radius: 10px !important;
}

.gm-style .gm-style-iw-d .gm-style-iw-c {
  margin: 0 !important;
  height: 100% !important;
  overflow: hidden !important;
  max-width: 400px !important;
  max-height: 400px !important;
  width: 300px !important;
  background-color: white !important;
  max-height: none !important;
}

.gm-style-iw-d {
  overflow: unset !important;
}

.info-window-container {
  display: flex;
  width: 100%;
  height: 100%;
}

.info-window-img {
  width: 45%;
  height: 100%;
  object-fit: cover;
}

/*  Level 4 Media Query: check if pointer device has limited accuracy like touchscreen */
@media (pointer:coarse) {
  .gm-style .gm-style-iw-c {
    height: 180px !important;
    width: 330px !important;
    padding: 0 !important;
    max-width: none !important;
    max-height: none !important;
  }
}

@media (pointer: fine) {
  .gm-style .gm-style-iw-c {
    padding: 0 !important;
    max-width: none !important;
    max-height: none !important;
    padding: 2px 2px 2px 2px !important;
  }
}

.gm-style-iw .gm-style-iw-c {
  width: 264px !important;
  height: 160px !important;
}

.gm-style-iw-d {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
}

.gm-style-iw-chr {
  position: absolute !important;
  width: 100% !important;
}

.gm-style-iw-chr .button-container {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  gap: 10px !important;
}

.gm-style-iw-chr {
  height: 20% !important;
}

.gm-ui-hover-effect {
  bottom: 12px !important;
  left: 10px !important;
  z-index: 9999 !important;
}

.gm-ui-hover-effect>span {
  width: 22px !important;
  height: 22px !important;
  margin: 12px !important;
  top: 7px !important;
}

.gm-style>div {
  z-index: 1;
}

.info-button-container {
  display: flex !important;
  gap: 30px !important;
  align-items: center !important;
  justify-content: center !important;
  position: relative !important;
  z-index: 99 !important;
}

.button-icon-container {
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
  background: transparent !important;
  width: auto !important;
  height: auto !important;
}

.bi-x-lg::before {
  content: "\f659";
  margin-top: 1.5rem;
}

.btn-icon i {
  font-size: 1.5rem !important;
  margin: 0 !important;
}

.bi-x-lg {
  color: black !important;
}

html, body {
  overscroll-behavior: none;
}

:root {
  touch-action: pan-x pan-y;
  height: 100% 
}
</style>
