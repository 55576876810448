<template>
    <div class="sticky-filter" :style="isMobile ? '' : 'height: 8vh; padding: 0px 1% 0 1%;'">

        <!-- location filter -->
        <div v-if="isMobile" class="col-sm-6 col-md-6 col-lg-2 location-filter-upper">
            <div class="search-container">
                <input v-model="dataFilters.address" id="address-input" type="text" class="form-control"
                    :placeholder="addressLabel">
                <i class="bi bi-search search-icon"></i>
            </div>
            <div class="btn-x">
                <span class="btn clear-button" @click="clearAddress">
                    X
                </span>
            </div>
        </div>

        <!-- Hamburger button -->
        <div class="row">
            <div :class="agentid ? 'col-4' : 'col-6'" class="d-flex justify-content-center align-items-center">
                <button v-if="isMobile" class="hamburger-btn-filters d-lg-none navbar-buttons" @click="toggleMobileMenu"
                    id="filters">
                    <transition name="fade-filters">
                        <i :class="isMobileMenuVisible ? 'bi bi-view-list' : 'bi bi-sliders'"
                            style="color: black !important;">
                        </i>
                    </transition>
                </button>
            </div>
            <div v-if="agentid" class="col-4 d-flex justify-content-center align-items-center">
                <button v-if="isMobile" class="hamburger-btn-filters navbar-buttons"
                    @click="handleRoutePathButtonClick"
                    :disabled="!isMapVisible || !agentid">
                    <transition name="rotate">
                        <i :class="['bi-signpost-2', { 'rotated': isRotating }]"
                            :style="{ color: isMapVisible && agentid ? 'black !important' : '#666666' }"></i>
                    </transition>
                </button>
            </div>
            <div :class="agentid ? 'col-4' : 'col-6'" class="d-flex justify-content-center align-items-center">
                <button v-if="isMobile" @click="toggleView" class="hamburger-btn-map navbar-buttons" id="map">
                    <transition name="icon">
                        <i :key="isMapVisible" :class="isMapVisible ? 'bi bi-house' : 'bi bi-geo-alt'"
                            style="color: black !important;"></i>
                    </transition>
                </button>
            </div>
        </div>

        <div v-show="isMobileMenuVisible || !isMobile" class="row sticky-filters"
            style="width: 100%; background: white;" :style="isMobile ? 'height: auto !important;margin-top: 15vh !important; position: absolute;' : ''">
            <div class="col-sm-12 col-md-6 col-lg-2 mb-2" style="background: white;">
                <div class="search-container">
                    <select @change="() => applyFilters(true)" v-model="dataFilters.state" id="state-select"
                        class="form-control">
                        <option value="" disabled selected>{{ $t('State') }}</option>
                        <option v-for="state in states" :key="state" :value="state">{{ state }}</option>
                    </select>
                    <i class="bi bi-search search-icon"></i>
                </div>
                <div class="btn-x">
                    <span class="btn clear-button" @click="clearFilters">
                        X
                    </span>
                </div>
            </div>
            <div v-if="!isMobile" class="col-sm-6 col-md-6 col-lg-2 mb-2">
                <div class="search-container">
                    <input @change="onChangeLocation" v-model="dataFilters.address" id="address-input" type="text"
                        class="form-control" :placeholder="addressLabel">
                    <i class="bi bi-search search-icon"></i>

                </div>
                <div class="btn-x">
                    <span class="btn clear-button" @click="clearAddress">
                        X
                    </span>
                </div>

            </div>
            <div class="col-sm-6 col-md-6 col-lg-2 mb-2">
                <button class="form-select price" @click="toggleDivPrice($event, 'price')">
                    {{ getPriceButtonText }}
                </button>

                <div v-if="divVisibility.price" class="filter-dropdown">

                    <div class="filter-section">
                        <div class="slider">
                            <input type="range" class="form-range" min="0" max="9000000" v-model="dataFilters.minPrice"
                                @input="validateMin" id="minRange">
                            <input type="range" class="form-range" min="0" max="9000000" v-model="dataFilters.maxPrice"
                                @input="validateMax" id="maxRange">
                        </div>
                    </div>
                    <div class="filter-section">
                        <div class="d-flex  justify-content-between mt-4" style="font-size: 0.90em;">
                            <span>Min: {{ filters.minPrice | formatPrice }}</span>
                            <span>Max: {{ filters.maxPrice | formatPrice }}</span>
                        </div>
                    </div>
                    <button @click="applyFilters" :style="{ backgroundColor: mergedButtonStyle.backgroundColor }"
                        class="view-details mt-1">{{ $t('Apply Filter') }}</button>
                </div>

            </div>

            <div class="col-sm-6 col-md-6 col-lg-2 mb-2">
                <button class="form-select bedbath" @click="toggleDivBedbath($event, 'bedbath')">
                    {{ getBedBathButtonText }}
                </button>
                <div v-if="divVisibility.bedbath" class="filter-dropdown">
                    <bed-bath-selection :bed-background-color="mergedButtonStyle.backgroundColor"
                        :bed-options="bedroomOptions" :bath-options="bathroomOptions"
                        @selection="selectOptionsForBedAndBath" @applyFilter="applyFilters" />
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-2 mb-2">
                <button class="form-select propertyType" @click="toggleDivPropertyType($event, 'propertyType')">
                    {{ getPropertyTypeButtonText }}
                </button>
                <div v-if="divVisibility.propertyType" class="filter-dropdown">
                    <property-type :property-types-background-color="'lightgrey'"
                        @property-type-selected="handleSelectedPropertyType"></property-type>
                </div>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-1 mb-2">
                <button class="view-details-g p-0"
                    :style="{ backgroundColor: filters.isQuickMoveIn ? mergedButtonStyle.backgroundColor : '', borderColor: filters.isQuickMoveIn ? mergedButtonStyle.backgroundColor : '' }"
                    :class="{ 'active-movein': filters.isQuickMoveIn }" @click="toggleMoveInDate">
                    {{ $t('Quick Move-in') }}
                </button>
            </div>
            <div class="col-sm-6 col-md-6 col-lg-1 mb-2">
                <button @click="clearFilters" :style="{ backgroundColor: mergedButtonStyle.backgroundColor }"
                    class="view-details"> {{ $t('Clear') }}</button>
            </div>

        </div>
    </div>
</template>

<script>
import BedBathSelection from './BedBathSelection.vue';
import PropertyType from './PropertyType.vue';


export default {
    props: {
        filters: {
            type: Object
        },
        isMobile: {
            type: Boolean
        },
        isMapVisible: {
            type: Boolean
        },
        isMobileMenuVisible: {
            type: Boolean
        },
        mergedButtonStyle: {
            type: Object
        },
        agentid: {
            type: String
        },
        addressLabel: {
            type: String
        }
    },
    data() {
        return {
            isRotating: {
                type: Boolean
            },
            dataFilters: {
                type: Object
            },
            selectedLabelForPropertyType: '',
            divVisibility: {
                price: false,
                bedbath: false,
                propertyType: false
            },
            states: ["Florida", "Texas"],
            selectedType: '',
            showLoadMoreButton: true,
            bedroomOptions: ['Studio', '1', '2', '3', '4', '5'],
            bathroomOptions: ['1', '2', '3', '4', '5'],
        }
    },
    emits: ['apply-filters', 'clear-filters', 'toggle-mobile-menu', 'toggle-view', 'clear-address', 'toggle-route-path-menu'],
    computed: {
        getPriceButtonText() {
            const defaultMinPrice = 0;
            const defaultMaxPrice = 9000000;

            if (
                (this.dataFilters.minPrice !== defaultMinPrice) ||
                (this.dataFilters.maxPrice !== defaultMaxPrice)
            ) {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                })
                const minPriceText = this.dataFilters.minPrice ? `${formatter.format(this.dataFilters.minPrice)}` : '0 $';
                const maxPriceText = this.dataFilters.maxPrice ? `${formatter.format(this.dataFilters.maxPrice)}` : '∞ $';

                return `${minPriceText} - ${maxPriceText}`;
            } else {
                return this.$t('Price');
            }
        },
        getPropertyTypeButtonText() {
            const selectedLabel = this.selectedLabelForPropertyType;
            if (selectedLabel) {
                return `${selectedLabel}`;
            } else {
                return this.$t('Property Type');
            }
        },
        getBedBathButtonText() {
            const defaultMinBed = '';
            const defaultMaxBed = '';
            const defaultMinBath = '';
            const defaultMaxBath = '';

            if (
                (this.dataFilters.noOfBeds !== defaultMinBed) ||
                (this.dataFilters.noOfBath !== defaultMinBath) ||
                (this.dataFilters.maxNoOfBeds !== defaultMaxBed) ||
                (this.dataFilters.maxNoOfBath !== defaultMaxBath)
            ) {
                const minBedText = this.dataFilters.noOfBeds >= 0 ? `${this.dataFilters.noOfBeds} Beds` : '0 Beds';
                const maxBedText = this.dataFilters.maxNoOfBeds >= 0 ? `${this.dataFilters.maxNoOfBeds} Beds` : '∞ Beds';
                const minBathText = this.dataFilters.noOfBath ? `${this.dataFilters.noOfBath} Baths` : '0 Baths';
                const maxBathText = this.dataFilters.maxNoOfBath ? `${this.dataFilters.maxNoOfBath} Baths` : '∞ Baths';

                return `${minBedText} - ${maxBedText} / ${minBathText} - ${maxBathText}`;
            } else {
                return this.$t('Bed/Bath');
            }
        },
    },
    filters: {
        formatPrice(value) {
            if (typeof +value !== 'number')
                return value
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            })
            return formatter.format(value)
        }
    },
    mounted() {
        this.dataFilters = this.filters;
    },
    components: {
        BedBathSelection,
        PropertyType
    },
    methods: {
        handleRoutePathButtonClick() {
            console.log('CLICK')
            this.toggleRotation();
            this.$emit('toggle-route-path-menu');
            console.log('CLICK')
        },
        toggleRotation() {
            this.isRotating = !this.isRotating;
            this.closeFilters();
        },
        toggleDiv(event, divKey, buttonClass) {
            event.preventDefault();
            this.divVisibility[divKey] = !this.divVisibility[divKey];
            const outsideClickHandler = (event) => {
                const dropdownElement = this.$el.querySelector('.filter-dropdown');
                const button = this.$el.querySelector(buttonClass);
                if (dropdownElement !== null) {
                    if (!dropdownElement.contains(event.target) && event.target !== button) {
                        this.divVisibility[divKey] = false;
                        document.removeEventListener('click', outsideClickHandler);
                    }
                }
            }
            if (this.divVisibility[divKey]) {
                document.addEventListener('click', outsideClickHandler);
            }
        },
        toggleDivPrice(event, divKey) {
            this.toggleDiv(event, divKey, '.form-select.price');
        },
        toggleDivBedbath(event, divKey) {
            this.toggleDiv(event, divKey, '.form-select.bedbath');
        },
        toggleDivPropertyType(event, divKey) {
            this.toggleDiv(event, divKey, '.form-select.propertyType');
        },
        applyFilters(resetDistance = false) {
            this.$emit('apply-filters', this.dataFilters, resetDistance);
        },
        toggleMoveInDate() {
            this.dataFilters.isQuickMoveIn = !this.filters.isQuickMoveIn;
            this.applyFilters();
        },
        clearFilters() {
            this.$emit('clear-filters');
        },
        handleSelectedPropertyType(type, label) {
            if (type == 0) {
                type = '';
            }
            this.selectedLabelForPropertyType = label;
            this.dataFilters.type = type;
            this.applyFilters();
        },
        selectOptionsForBedAndBath(selection) {
            if (selection.minBed == 'Studio') {
                selection.minBed = 0;
            }
            if (selection.maxBed == 'Studio') {
                selection.maxBed = 0;
            }
            this.dataFilters.noOfBeds = selection.minBed;
            this.dataFilters.noOfBath = selection.minBath;
            this.dataFilters.maxNoOfBeds = selection.maxBed;
            this.dataFilters.maxNoOfBath = selection.maxBath;
        },
        validateMin() {
            if (this.dataFilters.minPrice > this.dataFilters.maxPrice) {
                this.dataFilters.minPrice = this.dataFilters.maxPrice;
            }
        },
        validateMax() {
            if (this.dataFilters.maxPrice < this.dataFilters.minPrice) {
                this.dataFilters.maxPrice = this.dataFilters.minPrice;
            }
        },
        onChangeLocation() {
            this.$emit('onChangeLocation');
        },
        toggleMobileMenu() {
            this.$emit('toggle-mobile-menu');
        },
        toggleView() {
            this.$emit('toggle-view');
        },
        clearAddress() {
            this.$emit('clear-address');
        },
        closeFilters() {
            this.$emit('close-filters');
        }
    }
}
</script>

<style scoped>
.sticky-filter {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #fefefe;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 10px 0 10px;
}

.row {
    display: flex;
    flex-wrap: wrap;
    height: 50%;
}

.sticky-filter .hamburger-btn-filters,
.hamburger-btn-canvas,
.hamburger-btn-map {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
    width: 100%;
}

.navbar-buttons {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.bi-signpost-2 {
    transition: transform 0.5s ease-in-out;
    color: black;
}

.rotated {
    transform: rotate(360deg);
    color: black;
}

.search-container {
    position: relative;
    width: 100%;
}

.search-icon {
    position: absolute;
    left: 10px;

    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.form-control {
    padding-left: 30px;
}

.clear-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-100%);
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.btn-x {
    position: relative;
    width: 100%;
}

.form-select.price {
    font-size: .80rem;
}

.form-select.bedbath {
    font-size: .80rem;
}

.filter-section {
    margin-bottom: 15px;
}

.slider {
    position: relative;
}

.form-range {
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #e9ecef;
    outline: none;
    padding: 0;
    margin: 0;
    color: #FF6C00;
}

.filter-section {
    margin-bottom: 15px;
}

.view-details {
    background-color: #FF6C00;
    color: white;
    width: 100%;
    font-size: 13px;
    border: none;
    text-align: center;
    padding: 0;
}

.view-details-g {
    background-color: #888;
    border: #888;
    color: white;
    width: 100%;
    font-size: 13px;
    border: none;
    text-align: center;
}

.view-details:hover {
    background-color: #fb946e;
    color: white;
}

.view-details-g:hover {
    background-color: #bfb7b7;
    color: white;
}

.active-movein {
    background-color: #FF6C00;
    border: 1px solid #FF6C00;
    color: white;
}

input,
button,
select {
    font-size: 0.95rem;
    border: 1px solid #888;
    padding: 2px 32px 2px 12px;
    border-radius: 6px;
    height: 40px
}

.fade-filters-enter-active,
.fade-filters-leave-active {
    transition: opacity 1s none;
}

.fade-filters-enter,
.fade-filters-leave-to {
    opacity: 1;
}

.location-filter-upper {
    height: 50%;
    margin-bottom: .5rem;
    margin-top: .5rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.sticky-filters {
    height: 100%;
}

.bgwhite {
    background: white;
}
</style>