<template>
    <span>
        <div class="col-sm-12 col-md-8 col-lg-12 d-flex flex-wrap gap-2" style="width: 100%;">
            <div class="header-container">
                <div class="x-container">
                    <button class="bi-x-circle"
                        style="border: none; color: black; background: none; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;font-size: 1.25rem;"
                        @click="$emit('toggle-route-path-menu')">
                    </button>
                </div>
                <div class="btn-container">
                    <button @click="saveRoute" class="btn"
                        :style="{ backgroundColor: customStyle.backgroundColor, fontSize: customStyle.fontSize, color: customStyle.color }">
                        <i class="bi bi-pin"></i><span>{{ $t('Save Route') }}</span>
                    </button>
                    <button @click="showStorage" class="btn"
                        :style="{ backgroundColor: customStyle.backgroundColor, fontSize: customStyle.fontSize, color: customStyle.color }">
                        <i :class="!isStorageVisible ? 'bi bi-list' : 'bi bi-x-square'"></i> {{ $t('paths') }}
                    </button>
                    <button @click="clearRoute" class="btn"
                        :style="{ backgroundColor: customStyle.backgroundColor, fontSize: customStyle.fontSize, color: customStyle.color }">
                        <i class="bi bi-trash"></i><span>{{ $t('Clear Route') }}</span>
                    </button>
                    <button @click="openRoutesInGoogleMaps" class="btn"
                        :style="{ backgroundColor: customStyle.backgroundColor, fontSize: customStyle.fontSize, color: customStyle.color }">
                        <i class="bi bi-google"></i> <span>{{ $t('Open Route') }}</span>
                    </button>
                </div>
            </div>
            <div class="box-container">
                <div class="">
                    <div class="list-item bi-margin">
                        <span class="bi-record-circle"></span>
                        <span class="bi-three-dots-vertical"></span>
                    </div>
                    <div class="waypoint-item">
                        <input type="text" class="input-field" :placeholder="$t('Choose your starting point')"
                            v-model="origin.label" @focus="setFocus('origin', true)" @blur="setFocus('origin', false)"
                            id="origin" />
                        <button class="btn-remove-waypoint" @click="removeWaypoint(1, 'origin')">
                            <i class="bi bi-dash"></i>
                        </button>
                    </div>
                </div>
                <div v-for="(input, index) in waypoints" :key="index">
                    <div class="list-item bi-margin">
                        <span class="bi-circle"></span>
                        <span class="bi-three-dots-vertical"></span>
                    </div>
                    <div class="waypoint-item">
                        <input type="text" class="input-field" :placeholder="$t('Enter waypoint')" v-model="input.label"
                            @focus="setFocus('waypoints', true, index)" @blur="setFocus('waypoints', false, index)"
                            :id="`waypoint${index}`" />
                        <button class="btn-remove-waypoint" @click="removeWaypoint(index)">
                            <i class="bi bi-dash"></i>
                        </button>
                    </div>
                </div>
                <div class="" style="margin-bottom: 6px;">
                    <div class="list-item bi-margin">
                        <span class="bi-geo-alt"></span>
                    </div>
                    <div class="waypoint-item">
                        <input type="text" class="input-field" :placeholder="$t('Choose your destination')"
                            v-model="destination.label" @focus="setFocus('destination', true)"
                            @blur="setFocus('destination', false)" id="destination" />
                        <button class="btn-remove-waypoint" @click="removeWaypoint(1, 'destination')">
                            <i class="bi bi-dash"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex;">
            <button class="bi-plus-circle add-icon" @click="addWaypoint"
                style="margin-left: 2%; color: black !important;" v-show="waypoints.length < maxInput"
                :class="{ 'add-icon-mobile': isMobile }">
                <span style="margin-left: 10px; color: black !important;">{{ $t('Add waypoint') }}</span>
            </button>
            <button style="background: transparent; border: none; color: black !important;" @click="drawRoute">
                <i class="bi bi-pencil-fill" style="color: black !important;"></i>
                Draw Route
            </button>
        </div>
    </span>
</template>

<script>
/* global google */ // Do not remove this line because it needs to be global for the Google Maps API to work
import { loadGoogleApi } from '../googleLoader';

export default {
    emits: ['drawRoute', 'saveRoute', 'clearRoute', 'showStorage', 'toggle-route-path-visibility'],
    props: {
        lang: {
            type: String,
            default: 'us'
        },
        apiKey: {
            type: String
        },
        userLocation: {
            type: Object,
        },
        isOpen: {
            type: Boolean,
        },
        isMobile: {
            type: Boolean
        },
        customStyle: {
            type: Object,
            default: () => ({})
        },
        mergedButtonStyle: {
            type: Object,
            default: () => ({})
        },
        isStorageVisible: {
            type: Boolean
        }
    },
    data() {
        return {
            maxInput: 2, // Maximum number of intermediate waypoints
            origin: { label: '', value: { lat: '', lng: '' }, isFocused: false, isHovered: false }, // Fixed starting point
            destination: { label: '', value: { lat: '', lng: '' }, isFocused: false, isHovered: false, state: '' }, // Fixed destination
            waypoints: [], // Draggable waypoints between Start and Destination
            savedRoutes: [],
            savedRoutesLocalStorage: [],
        };
    },
    async mounted() {
        //console.log('vustom style', this.customStyle);
        await loadGoogleApi();
        if (!window.google || !window.google.maps || !window.google.maps.places) {
            return;
        } else {
            this.initializeAutocompleteForWaypoint('origin');
            this.initializeAutocompleteForWaypoint('destination');
        }
        await this.setUserOrigin();

    },
    watch: {
        async userLocation() {
            await this.setUserOrigin();
        },
        lang(newVal) {
            // console.log("lang watcher", newVal, oldVal);
            this.$i18n.locale = newVal
        },
    },
    methods: {
        showStorage() {
            this.$emit('showStorage', this.isStorageVisible);
        },
        // route manupulation
        drawRoute() {
            //delete empty waypoints first
            if (this.waypoints.length > 0) {
                this.waypoints = this.waypoints.filter((waypoint) => {
                    return waypoint.label !== '' ||
                        waypoint.value.lat !== null && waypoint.value.lat !== undefined ||
                        waypoint.value.lng !== null && waypoint.value.lng !== undefined;
                })
            }
            this.$emit('drawRoute', { origin: this.origin, destination: this.destination, waypoints: this.waypoints });
        },
        reapplyRoute(route = []) {
            this.origin = route.origin;
            this.destination = route.destination;
            if (route.waypoints.length > 0) {
                this.reApplyWaypoints(route.waypoints);
            }

            this.drawRoute();
        },
        reApplyWaypoints(waypoints) {
            for (let x = 0; x < waypoints.length; x++) {
                this.waypoints.push(waypoints[x]);
            }

            this.$nextTick(() => {
                for (let i = 0; i < this.waypoints.length; i++) {
                    this.initializeAutocompleteForWaypoint(`waypoint${i}`);
                }
            });
        },
        saveRoute() {
            if (this.origin.label.length === 0 || !this.origin.value.lat || !this.origin.value.lng) {
                this.$toast.warning(this.$t('origin_missing_message'), {
                    position: 'top-right',
                    timeout: 5000,
                    closeButton: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    icon: 'warning',
                    rtl: false,
                });
                return;
            }

            if (this.destination.label.length === 0 || !this.destination.value.lat || !this.destination.value.lng) {
                this.$toast.warning(this.$t('destination_missing_message'), {
                    position: 'top-right',
                    timeout: 5000,
                    closeButton: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    icon: 'warning',
                    rtl: false,
                });
                return;
            }

            const currentRoute = [{ origin: this.origin, destination: this.destination, waypoints: this.waypoints }];
            currentRoute[0].waypoints = currentRoute[0].waypoints.filter(waypoint => {
                return waypoint.label.length > 0 && waypoint.value.lat && waypoint.value.lng;
            })

            let localStorageSavedRoutes = JSON.parse(localStorage.getItem('savedRoutes')) || [];
            const isDuplicate = localStorageSavedRoutes.some(route => {
                const originMatch =
                    route.origin.label === currentRoute[0].origin.label &&
                    route.origin.value.lat === currentRoute[0].origin.value.lat &&
                    route.origin.value.lng === currentRoute[0].origin.value.lng;

                const destinationMatch =
                    route.destination.label === currentRoute[0].destination.label &&
                    route.destination.value.lat === currentRoute[0].destination.value.lat &&
                    route.destination.value.lng === currentRoute[0].destination.value.lng;

                const waypointsMatch = route.waypoints.length === currentRoute[0].waypoints.length &&
                    route.waypoints.every((waypoint, index) =>
                        waypoint.label === currentRoute[0].waypoints[index]?.label &&
                        waypoint.value.lat === currentRoute[0].waypoints[index]?.value.lat &&
                        waypoint.value.lng === currentRoute[0].waypoints[index]?.value.lng
                    );

                return originMatch && destinationMatch && waypointsMatch;
            });

            if (isDuplicate) {
                this.$toast.warning(this.$t('location_exists'), {
                    position: 'top-right',
                    timeout: 5000,
                    closeButton: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    icon: 'warning',
                    rtl: false,
                });
                return;
            }

            const result = localStorageSavedRoutes.concat(currentRoute);

            this.renameRoutes(result);

            localStorage.setItem('savedRoutes', JSON.stringify(result));
            this.$emit('saveRoute');
            this.$toast.success(this.$t('route_saved_message'), {
                position: 'top-right',
                timeout: 5000,
                closeButton: true,
                pauseOnHover: true,
                hideProgressBar: false,
                icon: 'success',
                rtl: false,
            });
        },
        loadSavedRoutes() {
            const savedRoutes = localStorage.getItem('savedRoutes');
            if (savedRoutes) {
                this.savedRoutesLocalStorage = savedRoutes;
            }
        },
        clearRoute() {
            this.destination = { label: '', value: { lat: '', lng: '' }, isFocused: false, isHovered: false };
            this.waypoints = [];
            this.$emit('clearRoute');
        },
        reApplyRoute(index) {
            this.origin = this.savedRoutes[index].origin;
            this.destination = this.savedRoutes[index].destination;
            this.waypoints = this.savedRoutes[index].waypoints;

            this.drawRoute();
        },
        async addWaypoint(location = null, slugTitle = null) {

            // empty
            if (!location?.lat || !location?.lng) {
                this.creatEmptyWaypoint();
                return;
            }

            //origin
            if (!this.origin.value.lat && !this.origin.value.lng) {
                await this.setOriginByCoordinates(location, slugTitle);
                return;
            }

            // destination
            if (!this.destination.value.lat && !this.destination.value.lng) {
                await this.setDestinationByCoordinates(location, slugTitle);
                return;
            }

            // waypoint
            const waypointData = await this.getLocationLabelViaCoordinates(location, slugTitle);
            let newWaypoint = this.isWaypointValid(waypointData);

            if (this.waypoints.length < this.maxInput && newWaypoint) {
                this.creatEmptyWaypoint();
                this.$set(this.waypoints, this.waypoints.length - 1, waypointData);
                this.drawRoute();
                return;
            } else {
                this.$toast.warning(this.$t('max_waypoint_limit_reached_message'), {
                    position: 'top-right',
                    timeout: 5000,
                    closeButton: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    icon: 'warning',
                    rtl: false,
                });
                return;
            }
        },
        removeWaypoint(index, context = 'waypoints') {
            switch (context) {
                case 'origin':
                    this.origin = { label: '', value: { lat: '', lng: '' }, isFocused: false, isHovered: false };
                    break;
                case 'destination':
                    this.destination = { label: '', value: { lat: '', lng: '' }, isFocused: false, isHovered: false, state: '' };
                    if (this.waypoints.length >= 1) {
                        const lastWaypointindex = this.waypoints.length - 1;
                        this.destination = this.waypoints[lastWaypointindex];
                        this.waypoints.splice(lastWaypointindex, 1);
                    }
                    break;
                case 'waypoints':
                    this.waypoints.splice(index, 1);
                    break;
            }
            // this.$emit('clearRoute');
            this.drawRoute();
        },
        async setUserOrigin() {
            const userLocationData = await this.getLocationLabelViaCoordinates(this.userLocation);
            if (userLocationData) {
                this.origin = {
                    ...this.origin,
                    label: userLocationData.label,
                    value: userLocationData.value
                }
            }
        },
        async setOriginByCoordinates(location, slugTitle) {
            const originData = await this.getLocationLabelViaCoordinates(location, slugTitle);
            const newOrigin = this.isWaypointValid(originData)
            if (newOrigin) {
                this.origin = {
                    ...this.origin,
                    label: newOrigin.label,
                    value: newOrigin.value,
                    slugTitle
                }
                this.drawRoute();
            }
        },
        async setDestinationByCoordinates(location, slugTitle) {
            const destinationData = await this.getLocationLabelViaCoordinates(location, slugTitle);
            const newDestination = this.isWaypointValid(destinationData)
            if (newDestination) {
                this.destination = {
                    ...this.destination,
                    label: newDestination.label,
                    value: newDestination.value,
                    slugTitle
                }
                this.drawRoute();
            }
        },
        // coordinate recovery
        async getLocationLabelViaCoordinates(location, slugTitle) {
            if (!location.lat && !location.lng) {
                return
            }
            const geocodeURL = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=${this.apiKey}`;
            try {
                const response = await fetch(geocodeURL, { method: 'GET' });
                const data = await response.json();

                if (data.status === 'OK') {
                    return {
                        label: data.results[0].formatted_address,
                        value: { lat: location.lat, lng: location.lng },
                        slugTitle
                    };
                } else {
                    return null;
                }
            } catch (error) {
                return null;
            }
        },
        initializeAutocompleteForWaypoint(id) {
            const inputElement = document.getElementById(id);
            if (inputElement) {
                const autocomplete = new google.maps.places.Autocomplete(inputElement);
                autocomplete.addListener('place_changed', async () => {
                    const place = autocomplete.getPlace();
                    if (!place.geometry) {
                        console.log(`No details available for input: '${place.name}'`);
                        return;
                    }
                    if (id === 'origin') {
                        this.origin.label = place.formatted_address;
                        this.origin.value = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
                        this.drawRoute();
                    } else if (id === 'destination') {
                        this.destination.label = place.formatted_address;
                        this.destination.value = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
                        this.destination.state = place.address_components[2]?.long_name || '';
                        this.drawRoute();
                    } else {
                        const waypointIndex = parseInt(id.replace('waypoint', ''), 10);
                        if (!isNaN(waypointIndex)) {
                            const newWaypoint = this.isWaypointValid({ label: place.formatted_address, value: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() } });
                            if (newWaypoint) {
                                this.$set(this.waypoints, waypointIndex, newWaypoint);
                                this.drawRoute();
                            } else {
                                this.removeWaypoint(waypointIndex);
                                this.$toast.warning(this.$t('waypoint_exists_message'), {
                                    position: 'top-right',
                                    timeout: 5000,
                                    closeButton: true,
                                    pauseOnHover: true,
                                    hideProgressBar: false,
                                    icon: 'warning',
                                    rtl: false,
                                });
                            }
                        }
                    }
                });
            } else {
                console.error(`Input element with ID ${id} not found.`);
            }
        },
        // google maps
        openRoutesInGoogleMaps() {
            if (this.origin.label.length === 0 || !this.origin.value.lat || !this.origin.value.lng) {
                this.$toast.warning(this.$t('origin_missing_message'), {
                    position: 'top-right',
                    timeout: 5000,
                    closeButton: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    icon: 'warning',
                    rtl: false,
                });
                return;
            }

            if (this.destination.label.length === 0 || !this.destination.value.lat || !this.destination.value.lng) {
                this.$toast.warning(this.$t('destination_missing_message'), {
                    position: 'top-right',
                    timeout: 5000,
                    closeButton: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    icon: 'warning',
                    rtl: false,
                });
                return;
            }

            const baseUrl = 'https://www.google.com/maps/dir/?api=1';
            const origin = `&origin=${encodeURIComponent(`${this.origin.value.lat},${this.origin.value.lng}`)}`;
            const destination = `&destination=${encodeURIComponent(`${this.destination.value.lat},${this.destination.value.lng}`)}`;
            const waypoints = this.buildWaypoints();
            const travelMode = `&travelmode=driving`;

            //console.log('google maps url ->', baseUrl + origin + destination + (waypoints ?? '') + travelMode);
            window.open(baseUrl + origin + destination + (waypoints ?? '') + travelMode);
        },
        openRouteInGoogleMaps(propertyCoordinates) {
            if (this.origin.label.length === 0 || !this.origin.value.lat || !this.origin.value.lng) {
                this.$toast.warning(this.$t('origin_missing_message'), {
                    position: 'top-right',
                    timeout: 5000,
                    closeButton: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    icon: 'warning',
                    rtl: false,
                });
                return;
            }

            const baseUrl = 'https://www.google.com/maps/dir/?api=1';
            const origin = `&origin=${encodeURIComponent(`${this.origin.value.lat},${this.origin.value.lng}`)}`;
            const destination = `&destination=${encodeURIComponent(`${propertyCoordinates.lat},${propertyCoordinates.lng}`)}`;
            const travelMode = `&travelmode=driving`;


            // console.log('google maps url ->', baseUrl + origin + destination + travelMode);
            window.open(baseUrl + origin + destination + travelMode);
        },
        // utils
        setFocus(field, state, waypointIndex) {
            if (field === 'waypoints' && waypointIndex !== null) this.$set(this.waypoints[waypointIndex], 'isFocused', state);
            else if (this[field]) this[field].isFocused = state;
        },
        calculateDistanceForProperties(userPosition, propertyPosition) {
            const R = 6371;
            const toRadians = degrees => (degrees * Math.PI) / 180;

            const deltaLat = toRadians(propertyPosition.lat - userPosition.lat);
            const deltaLng = toRadians(propertyPosition.lng - userPosition.lng);

            const a = Math.sin(deltaLat / 2) ** 2 +
                Math.cos(toRadians(userPosition.lat)) * Math.cos(toRadians(propertyPosition.lat)) *
                Math.sin(deltaLng / 2) ** 2;

            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            return R * c;
        },
        orderProperties(userPosition, properties) {
            const propertiesWithDistance = properties.map(coordinates => {
                return {
                    ...coordinates,
                    distance: this.calculateDistanceForProperties(userPosition, coordinates)
                }
            });
            return propertiesWithDistance.sort((first, second) => first.distance - second.distance);
        },
        buildWaypoints() {

            if (!this.waypoints || this.waypoints.length < 1) {
                return null;
            }

            let waypointsObjects = [];
            waypointsObjects = this.waypoints.map(waypoint => ({
                lat: waypoint.value.lat,
                lng: waypoint.value.lng
            }));

            waypointsObjects = this.orderProperties(this.origin.value, waypointsObjects);

            let waypointsString = '&waypoints=';
            waypointsObjects.forEach(element => {
                waypointsString += encodeURIComponent(`${element.lat},${element.lng}|`);
            });

            return waypointsString;
        },
        isWaypointValid(waypointData) {
            const newWaypoint = {
                label: waypointData.label,
                value: waypointData.value,
                slugTitle: waypointData.slugTitle,
                isFocused: false,
                isHovered: false,
            };

            const isDuplicate = this.waypoints.some(waypoint =>
                waypoint.label === newWaypoint.label &&
                    waypoint.value.lat === newWaypoint.value.lat &&
                    waypoint.value.lng === newWaypoint.value.lng ||
                    waypoint.slugTitle ? waypoint.slugTitle === newWaypoint.slugTitle : false
            ) ||
                this.destination.label === newWaypoint.label &&
                this.destination.value.lat === newWaypoint.value.lat &&
                this.destination.value.lng === newWaypoint.value.lng ||
                this.destination.slugTitle ? this.destination.slugTitle === newWaypoint.slugTitle : false;

            if (!isDuplicate) {
                return newWaypoint;
            } else {
                this.$toast.warning(this.$t('waypoint_exists_message'), {
                    position: 'top-right',
                    timeout: 5000,
                    closeButton: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    icon: 'warning',
                    rtl: false,
                });
                return null;
            }
        },
        isDuplicated({ lat, lng, slugTitle }) {
            const waypointIndex = this.waypoints.findIndex(waypoint =>
                (waypoint.value.lat === lat && waypoint.value.lng === lng) ||
                waypoint.slugTitle === slugTitle
            );

            if (waypointIndex !== -1) {
                return { isDuplicated: true, index: waypointIndex, context: 'waypoints' };
            }

            if (
                (this.origin.value.lat === lat && this.origin.value.lng === lng) ||
                this.origin.slugTitle === slugTitle
            ) {
                return { isDuplicated: true, index: null, context: 'origin' };
            }

            if (
                (this.destination.value.lat === lat && this.destination.value.lng === lng) ||
                this.destination.slugTitle === slugTitle
            ) {
                return { isDuplicated: true, index: null, context: 'destination' };
            }

            return { isDuplicated: false, index: null, context: null };
        },
        creatEmptyWaypoint() {
            if (this.waypoints.length < this.maxInput) {
                this.waypoints.push({ label: '', value: '', isFocused: false, isHovered: false });
                this.$nextTick(() => {
                    this.initializeAutocompleteForWaypoint(`waypoint${this.waypoints.length - 1}`);
                });
            }
        },
        renameRoutes(routes) {
            let counter = 1;
            routes.forEach(route => {
                if (!route.title || /^Route \d+$/.test(route.title)) {
                    route.title = `Route ${counter}`;
                    counter++;
                }
            })
        }
    }
}
</script>

<style scoped>
.waypoint-item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    flex-direction: row-reverse;
}

/* .input-field {
    flex-grow: 1;
}

.btn-remove-waypoint {
    margin-left: 10px;
} */

.input-field {
    width: 100%;
    /* padding: 10px; */
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    font-size: 14px;
    color: #555;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-overflow: ellipsis;
    margin-top: 0.6rem;
    padding: unset !important;
    padding-left: 0.6rem !important;
    padding-right: 2rem !important;
}

.input-field-destination {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    font-size: 14px;
    color: #555;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-overflow: ellipsis;
}

.input-field::placeholder {
    color: #aaa;
}

.input-field::placeholder {
    color: #aaa;
}

.input-field:focus {
    outline: none;
    border-color: #ff562294;
}

.box-container {
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.2);
    margin: 30px 50px 15px;
}

.custom-btn {
    background-color: #ff5722;
    color: white;
    font-size: 14px;
    font-weight: bold;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    white-space: nowrap;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.15s linear, transform 0.5s linear;
}

.fade-enter {
    opacity: 0;
    transform: scale(0.9);
}

.fade-leave-to {
    opacity: 0;
    transform: scale(0.9);
}

/* .custom-btn:hover {
    background-color: #ff5722;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
} */

.main-container {
    display: flex;
    gap: 1rem;
    margin-top: 20px;
}

.drag-container {
    display: flex;
    flex-direction: column;
}

.destination-container {
    max-height: 50px;
}

.input-group input:focus {
    outline: none;
    box-shadow: none;
}

.route-path-container {
    padding-top: 2rem;
    position: sticky;
    top: 0;
    flex: 1;
}

.route-storage {
    margin-left: 22rem;
    margin-top: 6rem;
}

.input-wrapper {
    position: relative;
    min-width: 250px;
    display: flex;
    align-items: center;
    padding: 0;
}

.form-control {
    /* flex: 1;
    width: 100%; */
    width: 90%;
    padding-right: 1rem;
    border: 1px solid #888;
    box-sizing: border-box;
}

.side-add-icon,
.remove-icon {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    padding: 0;
    cursor: pointer;
}

.btn-remove-waypoint {
    font-size: 2rem;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    background: transparent !important;
    position: absolute !important;
    color: #3f3f3f !important;
    margin-top: 0.4rem !important;
    /* left: 82% !important; */
}

.side-add-icon {
    right: 35px;
}

.remove-icon {
    right: 10px;
}

.add-icon {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    padding: 0;
    cursor: pointer;
}

.add-icon span {
    font-size: 1rem;
}

.search-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 1rem;
    color: #888;
}

.bi-margin {
    margin-top: 0.6rem !important;
}

.menu-icons {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    padding-left: 15px;
    cursor: pointer;
}

.menu-x-icon {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    cursor: pointer;
    margin-left: -6rem;
    transform: translateY(-2rem);
}

.text {
    font-size: small;
    text-align: center;
    transform: translateY(70%);
    margin-left: -27px;

}

.list-item {
    visibility: visible;
    position: absolute;
    height: 29px;
    padding: 8px 0 0 25px;
    z-index: 4;
    margin-left: -4.5rem;
    cursor: grab;
    width: 20px;
}

.list-item-button-wrapper {
    visibility: visible;
    position: absolute;
    height: 29px;
    padding: 8px 0 0 25px;
    z-index: 4;
    margin-left: 15rem;
    cursor: grab;
    width: 20px;
    transform: translateY(-9px);
}

.button-wrapper {
    font-size: 5px;
    padding-bottom: 50px;
}

.custom-confirm-button {
    font-size: var(--button-font-size, 16px);
    font-weight: var(--button-font-weight, bold);
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    margin-top: 1rem;
}

.x-container {
    left: 15px;
    position: relative;
}

.btn-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-grow: 1;
}

/*.menu-icons-mobile {
    background: none;
    border: none;
    display: flex;
    justify-content: left;
    margin: 0 1rem;
    cursor: pointer;
}*/

/*.form-control-mobile {
    margin-left: 20%;
}

.add-icon-mobile {
    padding-left: 10px;
}*/
</style>
