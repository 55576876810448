import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'animate.css';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

Vue.config.productionTip = false;


Vue.use(VueSweetalert2);

Vue.use(Toast, {
  position: 'top-right',
  timeout: 3000,
  closeButton: true,
  pauseOnHover: true,
  hideProgressBar: false,
  rtl: false
});

new Vue({
  i18n,
  render: (h) => h(App),
}).$mount('#app');
