<template>
    <div class="app-container">
        <!-- Filters -->
        <div class="filters" :style="isMobile ? 'height: 15%;' : 'height: 8%'">
            <index-filters :filters="filters" :is-map-visible="isMapVisible" :is-mobile="isMobile"
                :is-mobile-menu-visible="isMobileMenuVisible" :merged-button-style="mergedButtonStyle"
                :agentid="filters.agentId" :addressLabel="addressLabel" @apply-filters="applyFilters"
                @clear-filters="clearFilters" @toggle-mobile-menu="toggleMobileMenu" @toggle-view="toggleView"
                @clear-address="clearAddress" @close-filters="closeFilters"
                @toggle-route-path-menu="toggleRoutepathMenu" />
        </div>
        <div v-show="isMobile && projectsInCards.length > 0 && showDivComponent">
            <div class="col-12 route-path-wrapper" v-show="isRoutePathMenuOpen">
                <div class="route-path-content">
                    <route-path-inputs ref="routePathInputsRef" :userLocation="userLocation" :apiKey="apikey"
                        :isMobile="isMobile" :isStorageVisible="isStorageVisible" @drawRoute="calculateRoute"
                        @saveRoute="displaySavedRoutes" @clearRoute="deleteDrawRoute"
                        :merged-button-style="mergedButtonStyle" @lang="lang" @close="close" @showStorage="showStorage"
                        @toggle-route-path-menu="toggleRoutepathMenu"
                        :customStyle="mergedButtonStyle"></route-path-inputs>
                </div>
            </div>
            <div v-show="isStorageVisible" class="col-12 route-storage-wrapper">
                <div class="route-storage-content" style="margin-top: -0.7rem;">
                    <route-path-storage ref="routePathStorageRef" @onSelectedRoute="reapplyRoute"
                        :customStyle="mergedButtonStyle"></route-path-storage>
                </div>
            </div>
        </div>
        <!-- Cards -->
        <div class="d-flex flex-nowrap bd-highlight"
            :style="isMobile ? 'height: 100%' : 'height: 92%; padding: 1%; gap: 1%;'">
            <div v-if="!isMobile || !isMapVisible"
                class="col-sm-12 col-md-12 col-lg-7 order-2 order-lg-1 d-flex flex-column"
                :style="isMobile ? 'width: 100%; height: 85%;margin-top: 10px;' : 'height: 100%; width: 59%;'">
                <div class=" flex-grow-1" style="height: 100%;">
                    <div v-if="!isLoading" class="row" :style="isMobile ? 'height: 100%' : 'height: 100%'">
                        <!-- no project found -->
                        <div v-if="projectsInCards.length === 0 && showDivComponent"
                            class="col-sm-12 col-md-12 col-lg-12 no-projects">
                            <div class="text-center" v-html="$t('No projects found')">
                            </div>
                        </div>
                        <!--  -->
                        <div :class="!isMobile ? 'scroll-content' : 'scroll-content-mobile'"
                            class="col-12 col-lg-12 order-1 order-lg-2" ref="scrollContainer">
                            <div :class="{ '': !isMobile }" ref="scrollContainer">
                                <div class="row">
                                    <!-- v-for loop for cards -->
                                    <div class="col-sm-12  col-md-6-custom col-lg-4"
                                        :style="isMobile ? 'margin-top: -2vh;' : ''" v-for="p in projectsInCards"
                                        :key="p.id" @click="goToDetailsPage(p.projectId || p._id, p.slugTitle)">
                                        <!-- Card buttons -->
                                        <template v-if="isMobile && filters.agentId">
                                            <div class="card-buttons-container">
                                                <button class="button-icon-container" :style="{
                                                    color: filters.agentId ? mergedButtonStyle.backgroundColor : '#666666',
                                                    background: 'none',
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                    fontSize: '1.25rem',
                                                    width: '100%',
                                                    height: '100%',
                                                }" @click="handleOpenInMaps(p, $event)">
                                                    <div class="icon-container">
                                                        <i class="bi bi-google" style="transform: scale(1.4);"></i>
                                                    </div>
                                                </button>
                                                <button class="button-icon-container" :style="{
                                                    color: filters.agentId ? mergedButtonStyle.backgroundColor : '#666666',
                                                    background: 'none',
                                                    border: 'none',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    cursor: 'pointer',
                                                    fontSize: '1.45rem',
                                                    width: '100%',
                                                    height: '100%',
                                                }" @click="handleAddToRoute(p, $event)">
                                                    <div class="icon-container">
                                                        <i :class="vetResult(p).isDuplicated ? 'bi bi-trash3' : 'bi-pin-angle-fill'"
                                                            style="transform: scale(1.4);"></i>
                                                    </div>
                                                </button>
                                            </div>
                                        </template>
                                        <div :class="isMobile ? 'card-mobile' : 'card'">
                                            <img class=" card-img card-img-top" :src="p.picture" alt="Home image">
                                            <div class="card-body text-center">
                                                <div>
                                                    <span :style="{ color: mergedButtonStyle.backgroundColor }"
                                                        class="orange-neo-text"> {{ p.projectType
                                                        }}</span>
                                                </div>
                                                <div class="card-text">{{ p.title }}</div>
                                                <h6 class="card-text">{{ p.builderName || p.builder }}</h6>
                                                <div>
                                                    <span :style="{ color: mergedButtonStyle.backgroundColor }"
                                                        class="orange-neo-text"> {{ p.city }}, {{
                                                            p.state }}</span>
                                                </div>
                                                <strong>
                                                    <h5 class="card-title" v-if="Number(p.price) !== 0"><strong>{{
                                                        $t('from') }} ${{
                                                                Number(p.price).toLocaleString() }}</strong></h5>
                                                </strong>
                                                <hr>
                                                <div>{{ $t(p.status) }} </div>
                                                <div v-if="checkIfIsQuickMoveIn(p)"
                                                    :style="{ color: mergedButtonStyle.backgroundColor, borderColor: mergedButtonStyle.backgroundColor }"
                                                    class="quick-movein-label"> {{ $t('quick move-in available') }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showDivComponent && showLoadMoreButton"
                                :class="isMobile ? 'load-more-btn-mobile' : 'load-more-btn'" style="margin-top: 3px">
                                <button v-if="!isLoading && showLoadMoreButton" @click="loadMore"
                                    :style="mergedButtonStyle">{{
                                        $t('Load More') }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Loader -->
            <neo-loader :is-loading="isLoading" :isMobile="isMobile" :merged-style="mergedButtonStyle" />
            <!-- Map -->
            <div v-show="isMapVisible || !isMobile" class="col-12 col-lg-5 map-column order-1 order-lg-2 map2"
                :style="isMobile ? 'height: 85%' : 'height: 100%; width: 40%;'">
                <div ref="mapContainer" class="map-container"
                    :style="isMobile && isMapVisible ? 'height: 100%; padding: 10px;' : 'height: 100%; width: 100%;'">
                    <div ref="map" class="map" style="border-radius: 30px;"></div>
                </div>
                <div v-show="isMobile && map && !isMobileMenuVisible && filters.agentId" class="joystick">
                    <div class="location-icon">
                        <div class="location-circle">
                            <i @click="centerUserPosition" class="bi bi-compass-fill"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
/* global google */ //Do not remove this line beacuse it needs to be global for the google maps api to work
import loaderSvg from '../assets/img/loader.svg';
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { getFilteredProjects, getStateForAgent } from "@/requestWrapper.js";
import BedBathSelection from "@/components/BedBathSelection.vue";
import PropertyType from "@/components/PropertyType.vue";
import RoutePathInputs from '@/components/RoutePathInputs.vue';
import RoutePathStorage from '@/components/RoutePathStorage.vue';
import { loadGoogleApi } from '../googleLoader';
import IndexFilters from './IndexFilters.vue';
import NeoLoader from './NeoLoader.vue';
import CardInfoButtons from './CardInfoButtons.vue';

export default {
    name: "YurekaiNeoMapBaseSearch",
    props: {
        apikey: {
            type: String,
            required: true
        },
        version: {
            type: String,
            default: 'weekly' //to discuss 
        },
        libraries: {
            type: String,
            default: ''
        },
        initiallatitude: {
            type: String,
            default: "27.9944024" // Default latitude for Florida
        },
        initiallongitude: {
            type: String,
            default: "-81.7602544" // Default longitude for Florida
        },
        initialzoom: {
            type: String,
            default: "6" // Default zoom level
        },
        buttonlabel: {
            type: String,
            default: 'Search By Map'
        },
        custombuttonstyle: {
            type: Object,
            default: () => ({})
        },
        userid: {
            type: String,
            default: ''
        },
        lang: {
            type: String,
            default: 'us'
        },
        urlprojects: {
            type: String,
            default: 'https://www.newestateonly.com/{lang}project/'
        },
        agentid: {
            type: String,
            default: ''
        },
        state: {
            type: String,
            default: 'Florida'
        }
    },
    components: {
        BedBathSelection,
        PropertyType,
        RoutePathInputs,
        RoutePathStorage,
        IndexFilters,
        NeoLoader,
        CardInfoButtons
    },
    data() {
        return {
            markercluster: null,
            isRoutePathMenuOpen: false,
            addressLabel: this.$t('Location'),
            geocoder: null,
            parsedQs: {},
            isDraggingOnMobile: false,
            userMarker: null,
            permissionStatus: 'denied',
            needZoomToUserPosition: true,
            preventEvent: false,
            isRotating: false,
            isGpsPositionAvaible: true,
            mapEventListeners: {},
            mapCenter: {
                lat: 0,
                lng: 0
            },
            navigationMode: false,
            userLocation: {

            },
            isMobile: window.innerWidth < 992,
            isCardVisible: false,
            isMapVisible: true,
            isLoading: false,
            isOpen: true,
            rotation: false,
            defaultbuttonstyle: {
                backgroundColor: "#FF6C00",
                color: "white",
                border: "none",
                borderRadius: "25px",
                padding: "10px 20px",
                fontSize: "12px",
                fontWeight: "bold",
                cursor: "pointer",
                outline: "none",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
            },
            loaderSvg,
            showDivComponent: true,

            projectsInCards: [],
            allCardsProject: [],
            allProjects: [],

            isLocationLocked: false,
            filters: {
                limit: 4000,
                state: '',
                address: '',
                lat: '',
                lng: '',
                noOfBeds: '',
                noOfBath: '',
                maxNoOfBeds: '',
                maxNoOfBath: '',
                zipCode: '',
                addressType: '',
                distance: 688504,
                sortByName: '',
                type: '',
                minPrice: 0,
                maxPrice: 9000000,
                isQuickMoveIn: false,
                propertyType: '',
                agentId: ''
            },

            divVisibility: {
                price: false,
                bedbath: false,
                propertyType: false
            },
            states: ["Florida", "Texas"],
            selectedType: '',
            showLoadMoreButton: false,
            bedroomOptions: ['Studio', '1', '2', '3', '4', '5'],
            bathroomOptions: ['1', '2', '3', '4', '5'],
            defaultUrlProjects: 'https://www.newestateonly.com/{lang}project/',
            acceptedOrigins: ['https://www.newestateonly.com', 'https://staging.newestateonly.com', 'https://staging-plus.newestateonly.com'],

            map: null,
            markers: [],
            selectedLabelForPropertyType: '',
            showButtonMap: true,
            place: null,
            isMobileMenuVisible: false,
            isCanvasOpen: false,
            areFiltersOpen: false,
            isStorageVisible: false,
        };
    },
    methods: {
        toggleRoutepathMenu() {
            this.isRoutePathMenuOpen = !this.isRoutePathMenuOpen;
            if (this.isStorageVisible) {
                this.isStorageVisible = false;
            }
        },
        toggleMobileMenu() {
            this.isMobileMenuVisible = !this.isMobileMenuVisible;
            if (this.isRoutePathMenuOpen) {
                this.isRoutePathMenuOpen = false;
            }
            if (this.isStorageVisible) {
                this.isStorageVisible = false;
            }
            this.isOpen = !this.isOpen;
        },
        showStorage() {
            this.isStorageVisible = !this.isStorageVisible;
        },
        toggleView() {
            if (this.isRoutePathMenuOpen) {
                this.isRoutePathMenuOpen = false;
            }
            if (this.isStorageVisible) {
                this.isStorageVisible = false;
            }
            if (this.isMobile) {
                if (this.isMapVisible) {
                    this.isMapVisible = false;
                    this.isCardVisible = true;
                } else {
                    this.isMapVisible = true;
                    this.isCardVisible = true;
                }
            } else {
                this.isMapVisible = !this.isMapVisible;
            }
        },
        updateAddressLabelWhenMapEventTriggers() {
            if (this.isLocationLocked) {
                return;
            }
            if (!this.geocoder) {
                this.geocoder = new google.maps.Geocoder();
            }
            this.geocoder.geocode({ location: this.map.getBounds().getCenter() }, (results, status) => {
                if (status == 'OK') {
                    // console.log('geocodingResult', results);
                    const address = results[0].address_components.find(result => {
                        return result.types.includes('locality');
                    })
                    // console.log('address', address?.long_name || this.$t('Location'));
                    this.addressLabel = address?.long_name || this.$t('Location');
                } else {
                    this.addressLabel = this.$t('Location');
                }
            });
        },
        filterVisibleCards() {
            const bounds = this.map.getBounds();
            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();
            this.allCardsProject = this.allProjects.filter((item) => {
                const lat = item.location.coordinates[1];
                const lng = item.location.coordinates[0];
                if (
                    lat < southWest.lat() || lat > northEast.lat() ||
                    lng < southWest.lng() || lng > northEast.lng()
                ) {
                    return false;
                } else {
                    return true;
                }
            });

            if (this.allCardsProject.length > 0) {
                let slicedProjects = this.allCardsProject.slice(0, 6);
                this.projectsInCards = slicedProjects;
                this.allCardsProject.length > 6 ? this.showLoadMoreButton = true : this.showLoadMoreButton = false;
            } else {
                this.allCardsProject = this.allProjects;
                let slicedProjects = this.allCardsProject.slice(0, 6);
                this.projectsInCards = slicedProjects;
                this.allCardsProject.length > 6 ? this.showLoadMoreButton = true : this.showLoadMoreButton = false;
            }

            // console.log('allCardsProject', this.allCardsProject.length);
            // console.log('projectsInCards', this.projectsInCards.length);
        },
        vetResult(p) {
            const vetResult = this.$refs.routePathInputsRef.isDuplicated({
                lat: parseFloat(p.location.coordinates[1]),
                lng: parseFloat(p.location.coordinates[0]),
                slugTitle: p.slugTitle
            });
            // console.log('vetresult', vetResult)
            return vetResult;
        },
        centerUserPosition() {
            if (this.userLocation && this.map) {
                this.map.setCenter(this.userLocation);
                this.map.setZoom(10);
            } else {
                console.warn("User location or map is not available.");
            }
        },
        removeBackdrop() {
            const canvas = this.$refs.offcanvasRef;
            if (!canvas) {
                console.warn("Offcanvas element not found!");
                return;
            }
            const backdrops = document.querySelectorAll('.offcanvas-backdrop');
            if (backdrops.length > 1) backdrops[0].remove();

            setTimeout(() => {
                this.rotation = false;
            }, 400);
        },
        displaySavedRoutes() {
            this.$refs.routePathStorageRef.loadRoutes();
        },
        reapplyRoute(route) {
            this.$refs.routePathInputsRef.reapplyRoute(route);
        },
        close() {
            if (!this.isMobile) this.isOpen = !this.isOpen;
        },
        closeCanvas() {
            const canvas = this.$refs.offcanvasRef;
            if (!canvas) {
                console.warn("Offcanvas element not  found");
                return;
            }

            const closeButton = canvas.querySelector('.close-button-canvas');
            if (closeButton) closeButton.click();
        },
        closeFilters() {
            if (this.isMobileMenuVisible) {
                this.isMobileMenuVisible = false;
            }

            this.rotation = true;
        },
        addToRoute(location, slugTitle) {
            this.$refs.routePathInputsRef.addWaypoint(location, slugTitle);
        },
        handleAddToRoute(p, event) {
            event.stopPropagation();
            const location = {
                lat: p.location.coordinates[1],
                lng: p.location.coordinates[0]
            }
            const vetResult = this.vetResult(p);
            if (vetResult.isDuplicated) {
                this.$refs.routePathInputsRef.removeWaypoint(vetResult.index, vetResult.context);
                this.isMapVisible = true;
            } else {
                this.addToRoute(location, p.slugTitle);
                this.isMapVisible = true;
            }
        },
        handleOpenInMaps(p, event) {
            event.stopPropagation();
            const location = {
                lat: p.location.coordinates[1],
                lng: p.location.coordinates[0]
            }
            this.$refs.routePathInputsRef.openRouteInGoogleMaps(location);
        },
        async calculateRoute({ origin, destination, waypoints }) {

            if ((!origin.value.lat || !origin.value.lng) && !this.isGpsPositionAvaible) {
                this.$swal({
                    title: this.$t('start_position_and_gps_unavalable_title'),
                    text: this.$t('start_position_and_gps_unavalable_message'),
                    icon: 'warning',
                    confirmButtonText: this.$t('got_it'),
                    confirmButtonColor: this.mergedButtonStyle.backgroundColor,
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                    customClass: {
                        confirmButton: 'custom-confirm-button'
                    },
                    didOpen: () => {
                        const button = document.querySelector('.custom-confirm-button');
                        if (button) {
                            button.style.setProperty('--button-font-size', this.mergedButtonStyle.fontSize);
                            button.style.setProperty('--button-font-weigth', this.mergedButtonStyle.fontWeight);
                        }
                    },
                    preConfirm: () => {
                        const offcanvasElement = document.getElementById('offcanvasBottom');
                        const offcanvas = new bootstrap.Offcanvas(offcanvasElement);
                        offcanvas.show();
                        this.removeBackdrop();
                    }
                });
                this.directionsRenderer.setDirections({ routes: [] });
                this.navigationMode = false;
                await this.refreshMap();
                return;
            }

            if ((origin.label.length === 0 || !origin.value.lat || !origin.value.lng) && (destination.label.length === 0 || !destination.value.lat || !destination.value.lng)) {
                this.$toast.warning(this.$t('start_and_destination_missing_message'), {
                    position: 'top-right',
                    timeout: 5000,
                    closeButton: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    icon: 'warning',
                    rtl: false,
                });
                this.directionsRenderer.setDirections({ routes: [] });
                this.navigationMode = false;
                await this.refreshMap();
                return;
            }

            if (origin.label.length === 0 || !origin.value.lat || !origin.value.lng) {
                this.$toast.warning(this.$t('origin_missing_message'), {
                    position: 'top-right',
                    timeout: 5000,
                    closeButton: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    icon: 'warning',
                    rtl: false,
                });
                this.directionsRenderer.setDirections({ routes: [] });
                this.navigationMode = false;
                await this.refreshMap();
                return;
            }

            if (destination.label.length === 0 || !destination.value.lat || !destination.value.lng) {
                this.$toast.warning(this.$t('destination_missing_message'), {
                    position: 'top-right',
                    timeout: 5000,
                    closeButton: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    icon: 'warning',
                    rtl: false,
                });
                this.directionsRenderer.setDirections({ routes: [] });
                this.navigationMode = false;
                await this.refreshMap();
                return;
            }

            this.isLoading = true;
            try {
                this.getStateFromLatLng({ lat: destination.value.lat, lng: destination.value.lng });
                await this.refreshMap();
                this.directionsRenderer.setMap(this.map);
                let mappedWaypoints = [];

                if (waypoints) {
                    mappedWaypoints = waypoints.map(waypoint => ({
                        location: waypoint.value,
                        stopover: true
                    }));
                }

                this.directionsService.route({
                    origin: origin.value,
                    destination: destination.value,
                    provideRouteAlternatives: true,
                    ...(mappedWaypoints.length > 0 && { waypoints: mappedWaypoints }),
                    travelMode: google.maps.TravelMode.DRIVING,
                }, (response, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        this.directionsRenderer.setDirections(response);
                        this.showInputForSavingRoute = true;

                        google.maps.event.removeListener(this.mapEventListeners['customZoom']);
                        google.maps.event.removeListener(this.mapEventListeners['customDragend']);
                        google.maps.event.removeListener(this.mapEventListeners['customClick']);
                    } else {
                        this.$toast.error(this.$t('directions_error_message'), {
                            position: 'top-right',
                            timeout: 5000,
                            closeButton: true,
                            pauseOnHover: true,
                            hideProgressBar: false,
                            icon: 'warning',
                            rtl: false,
                        });

                    }
                });
            } catch (err) {
                console.error(err);
                this.$toast.error(this.$t('directions_error_message'), {
                    position: 'top-right',
                    timeout: 5000,
                    closeButton: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    icon: 'warning',
                    rtl: false,
                });
                this.directionsRenderer.setDirections({ routes: [] });
                this.navigationMode = false;
                await this.refreshMap();
            } finally {
                this.navigationMode = true;
                this.isLoading = false;
                if (this.isMobile) this.closeCanvas();
            }
        },
        async deleteDrawRoute() {
            if (!this.directionsRenderer.getDirections()) {
                return;
            }
            this.directionsRenderer.setDirections({ routes: [] });
            this.navigationMode = false;
            await this.refreshMap();
        },
        checkPermissionAndWatchLocation() {
            if (navigator.permissions) {
                this.detectUserLocation();
                navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
                    permissionStatus.onchange = () => {
                        switch (permissionStatus.state) {
                            case 'denied':
                                this.permissionStatus = 'denied';
                                this.detectUserLocation();
                                if (this.userMarker) {
                                    this.userMarker.setMap(null);
                                    this.userMarker = null;
                                }
                                break;
                            case 'granted':
                                this.permissionStatus = 'granted';
                                this.needZoomToUserPosition = true;
                                this.detectUserLocation();
                                break;
                            case 'prompt':
                                this.permissionStatus = 'prompt';
                                break;
                        }
                    };
                });
            }
        },
        stopWatchingLocation() {
            if (this.watchId) {
                navigator.geolocation.clearWatch(watchID);
                console.log("Geolocation watch stopped.");
            }
        },
        detectUserLocation() {
            const useMockLocation = false;
            const mockLocation = {
                lat: 29.110833380698942,
                lng: -81.85557542904736,
            };
            if (navigator.geolocation) {
                this.watchId = navigator.geolocation.watchPosition(
                    async (position) => {
                        this.userLocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        if (useMockLocation) {
                            this.userLocation = mockLocation;
                        }
                        const state = await this.getUserState(this.userLocation);
                        if (state == this.filters.state && this.needZoomToUserPosition) {
                            this.preventEvent = true;
                            if (!this.userMarker) {
                                this.addUserMarker(this.userLocation);
                            }
                            this.map.panTo(this.userLocation);
                            this.map.setZoom(10);
                            this.preventEvent = false;
                            this.needZoomToUserPosition = false;
                        }
                        return true;
                    },
                    (error) => {
                        switch (error.code) {
                            case error.PERMISSION_DENIED:
                                this.userLocation = null;
                                console.error("User denied the request for Geolocation.");
                                this.$toast.error(this.$t('location_permission_denied_message'), {
                                    position: 'top-right',
                                    timeout: 5000,
                                    closeButton: true,
                                    pauseOnHover: true,
                                    hideProgressBar: false,
                                    icon: 'error',
                                    rtl: false
                                });
                                break;
                            case error.POSITION_UNAVAILABLE:
                                this.userLocation = null;
                                console.error("Location information is unavailable.");
                                this.$toast.error(this.$t('location_unavailable_message'), {
                                    position: 'top-right',
                                    timeout: 5000,
                                    closeButton: true,
                                    pauseOnHover: true,
                                    hideProgressBar: false,
                                    icon: 'error',
                                    rtl: false
                                });
                                break;
                            case error.TIMEOUT:
                                console.error("The request to get user location timed out.");
                                break;
                            default:
                                this.$toast.error(this.$t('unknown_error_message'), {
                                    position: 'top-right',
                                    timeout: 5000,
                                    closeButton: true,
                                    pauseOnHover: true,
                                    hideProgressBar: false,
                                    icon: 'warning',
                                    rtl: false,
                                });
                                break;
                        }
                        this.isGpsPositionAvaible = false;
                        this.userLocation = {};
                        console.log('GPS POSITION STATE =>', this.isGpsPositionAvaible);
                        return false;
                    },
                    {
                        enableHighAccuracy: true,
                        maximumAge: 10000,
                        timeout: 10000,
                    }
                )
            } else {
                console.error("Geolocation is not supported by this browser.");

                this.$toast.warning(this.$t('geolocation_not_supported_message'), {
                    position: 'top-right',
                    timeout: 5000,
                    closeButton: true,
                    pauseOnHover: true,
                    hideProgressBar: false,
                    icon: 'warning',
                    rtl: false,
                });


                this.isGpsPositionAvaible = false;
                this.userLocation = {};
                console.log('GPS POSITION STATE =>', this.isGpsPositionAvaible);
                return false;
            }
        },
        async addUserMarker(location) {
            this.userMarker = new google.maps.Marker({
                title: "You are here!",
                position: location,
                map: this.map,
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 10,
                    fillColor: '#0b52ff',
                    fillOpacity: 1,
                    strokeColor: 'rgba(254, 254, 254, 1)',
                    strokeOpacity: 1,
                    strokeWeight: 5
                },
            });

            this.infoWindowUser = new google.maps.InfoWindow({
                content: `
                    <div style="font-size: 14px;text-align: center;">
                        <h3>Your Location</h3>
                        <p>Lat: ${location.lat}</p>
                        <p>Lng: ${location.lng}</p>
                    </div>`
            });

            this.userMarker.addListener("click", () => {
                this.infoWindowUser.open(this.map, this.userMarker);
            });

        },
        onChangeLocation() {
            this.isLocationLocked = true;
        },
        toggleDiv(event, divKey, buttonClass) {
            event.preventDefault();
            this.divVisibility[divKey] = !this.divVisibility[divKey];
            const outsideClickHandler = (event) => {
                const dropdownElement = this.$el.querySelector('.filter-dropdown');
                const button = this.$el.querySelector(buttonClass);
                if (dropdownElement !== null) {
                    if (!dropdownElement.contains(event.target) && event.target !== button) {
                        this.divVisibility[divKey] = false;
                        document.removeEventListener('click', outsideClickHandler);
                    }
                }
            }
            if (this.divVisibility[divKey]) {
                document.addEventListener('click', outsideClickHandler);
            }
        },
        toggleDivPrice(event, divKey) {
            this.toggleDiv(event, divKey, '.form-select.price');
        },
        toggleDivBedbath(event, divKey) {
            this.toggleDiv(event, divKey, '.form-select.bedbath');
        },
        toggleDivPropertyType(event, divKey) {
            this.toggleDiv(event, divKey, '.form-select.propertyType');
        },
        async fillStateFilter() {
            if (this.filters.agentId) {
                let states = await getStateForAgent(this.filters.agentId)
                this.states = states?.result
                this.filters.state = this.states.includes(this.filters.state) ? this.filters.state : (this.states[0] || '');
                let { lat, lng } = await this.getLatAndLngFromState(this.filters.state);
                this.filters.lat = lat;
                this.filters.lng = lng;
            } else if (this.filters.state == '') {
                this.filters.state = 'Florida';
                let { lat, lng } = await this.getLatAndLngFromState(this.filters.state);
                this.filters.lat = lat;
                this.filters.lng = lng;
            }
            this.filters.distance = 688504;
        },
        async toggleComponent() {
            this.isLoading = true;

            let allProjects = await this.getAllProjects(this.filters);
            this.allProjects = allProjects.result || [];

            this.allCardsProject = this.allProjects;
            let slicedProjects = this.allCardsProject.slice(0, 6);
            this.projectsInCards = slicedProjects;
            (this.allCardsProject.length - this.projectsInCards.length) > 6 ? this.showLoadMoreButton = true : this.showLoadMoreButton = false;

            const lat = this.filters.lat || this.initiallatitude;
            const lng = this.filters.lng || this.initiallongitude;

            await this.createMap(allProjects, parseFloat(lat), parseFloat(lng), this.initialzoom);
            this.initializeServices();

            this.showButtonMap = false;
            this.isLoading = false;
        },

        async getLimitedFilteredProjects(filters) {
            this.isLoading = true;
            let response = await getFilteredProjects(filters) || [];
            this.projectsInCards = this.projectsInCards.concat(response.result);
            this.showLoadMoreButton = response.result.length >= 6 || response.length == 0 ? true : false;
            this.isLoading = false;
            return response;
        },
        async getAllProjects(filters) {

            return new Promise(async (resolve, reject) => {
                try {
                    let response = await getFilteredProjects(filters) || [];
                    resolve(response);
                } catch (error) {
                    reject(error);
                }
            });
        },
        goToDetailsPage(_id, slugTitle) {
            if (!this.urlprojects)
                this.urlprojects = this.defaultUrlProjects
            if (!this.acceptedOrigins.includes((new URL(decodeURIComponent(this.urlprojects))).origin))
                this.urlprojects = this.defaultUrlProjects
            let url = ''
            if (this.urlprojects.includes('landing')) {
                if (decodeURIComponent(this.urlprojects).includes('{idProgetto}'))
                    url = decodeURIComponent(this.urlprojects).replace(/{idProgetto}/g, _id)
                else
                    url = decodeURIComponent(this.urlprojects).replace(/=$/g, '') + "=" + _id
                url = `${this.urlprojects}?N_projectId=${_id}&N_type=project${["es", "pt"].includes(this.lang) ? `&lang=${this.lang}` : ''}`;
            } else {
                url = this.urlprojects.replace("{lang}", (["es", "pt"].includes(this.lang) ? `${this.lang}/` : '')) + slugTitle
            }
            window.open(url, '_blank');
        },
        async computeQuickMoveInStatus(projects) {
            for (let i = 0; i < projects.length; i++) {
                projects[i].isQuickMoveIn = await this.checkIfIsQuickMoveIn(projects[i]);
            }
        },
        checkIfIsQuickMoveIn(project) {
            if (project.isQuickMoveIn) {
                return true;
            }
            if (project.zone && project.zone.buildings) {
                for (let i = 0; i < project.zone.buildings.length; i++) {
                    if (project.zone.buildings[i].isQuickMoveIn) {
                        return true;
                    }
                }
            }
            if (project.homedesign) {
                for (let i = 0; i < project.homedesign.length; i++) {
                    if (project.homedesign[i].isQuickMoveIn) {
                        return true;
                    }
                }
            }
            if (project.zone && project.zone.residences) {
                for (let i = 0; i < project.zone.residences.length; i++) {
                    if (project.zone.residences[i].isQuickMoveIn) {
                        return true;
                    }
                }
            }
            if (project.zone && project.level2Residences) {
                for (let i = 0; i < project.level2Residences.length; i++) {
                    if (project.level2Residences[i].isQuickMoveIn) {
                        return true;
                    }
                }
            }
            return false;

        },
        async loadMore() {
            this.isLoading = true;
            const currentIndex = this.projectsInCards.length;
            const nextProjects = this.allCardsProject.slice(currentIndex, currentIndex + 6);
            this.projectsInCards = [...this.projectsInCards, ...nextProjects];
            if ((this.allCardsProject.length - this.projectsInCards.length) > 0) {
                this.showLoadMoreButton = true;

            } else {
                this.showLoadMoreButton = false;
            }
            this.isLoading = false;
        },

        async clearFilters() {
            await this.fillStateFilter();
            let initiallatitude = (this.filters.lat != '' ? this.filters.lat : this.initiallatitude);
            let initiallongitude = (this.filters.lng != '' ? this.filters.lng : this.initiallongitude);

            this.filters.address = '';
            this.filters.lat = '';
            this.filters.lng = '';
            this.filters.zipCode = '';
            this.filters.addressType = '';
            this.filters.sortByName = '';
            this.filters.type = '';
            this.filters.minPrice = 0;
            this.filters.maxPrice = 9000000;
            this.filters.noOfBeds = '';
            this.filters.noOfBath = '';
            this.filters.maxNoOfBeds = '';
            this.filters.maxNoOfBath = '';
            this.filters.isQuickMoveIn = false;
            this.filters.propertyType = '';
            this.selectedLabelForPropertyType = '';

            this.isLocationLocked = false;

            await this.deleteDrawRoute();
            await this.refreshData(parseFloat(initiallatitude), parseFloat(initiallongitude), parseFloat(this.initialzoom));
        },
        clearSomeFilters() {
            this.isLocationLocked = false;
            this.filters.address = '';
            this.filters.lat = '';
            this.filters.lng = '';
            this.filters.zipCode = '';
            this.filters.addressType = '';
            this.filters.sortByName = '';
        },
        async clearAddress() {
            this.filters.address = '';
            let lat = this.filters.lat
            let lng = this.filters.lng
            this.filters.lat = '';
            this.filters.lng = '';

            this.isLocationLocked = false;

            await this.refreshData(lat, lng, this.map.getZoom());
        },
        async applyFilters(resetDistance) {
            this.isMobileMenuVisible = false;
            this.isOpen = false;

            this.divVisibility.price = false;
            this.divVisibility.bedbath = false;
            this.divVisibility.propertyType = false;

            if (this.filters.state == '') {
                this.filters.state = 'Florida';
                await this.refreshData(parseFloat(this.initiallatitude), parseFloat(this.initiallongitude), parseInt(this.initialzoom));
                this.filters.state = '';
                return;
            }

            if (this.filters.address !== '') {
                let stateFromAddress = this.searchForStateAfterAddressInput(this.place);
                if (this.filters.state !== stateFromAddress) {
                    this.clearSomeFilters();
                }
            }
            let dataLocation = await this.getLatAndLngFromState(this.filters.state);

            if (resetDistance) {
                this.filters.distance = 688504;
                // console.log('quiii 1')
                await this.refreshData(dataLocation.lat, dataLocation.lng, 7);
            }
            // console.log('quiii 2')

            await this.refreshData(dataLocation.lat, dataLocation.lng, this.map.getZoom() || 7);

        },
        async refreshData(lat, lng, zoom) {
            this.isLoading = true;
            let all = await this.getAllProjects(this.filters);
            if (all && all.result && all.result.length > 0) {
                let slicedProjects = all.result.slice(0, 6);
                this.projectsInCards = slicedProjects;
                this.allProjects = all.result;
                this.allProjects.length > 6 ? this.showLoadMoreButton = true : this.showLoadMoreButton = false;
            } else {
                this.allProjects = [];
                this.projectsInCards = [];
                this.showLoadMoreButton = false;
                this.clearMarkers();
                console.log("No projects found.");

            }
            await this.createMap(this.allProjects, lat, lng, 7);

            this.isLoading = false;
        },
        async refreshDataAfterMapEventTriggered(lat, lng, zoom, zoomChanged) {

            if (this.isLocationLocked) return;


            this.isLoading = true;

            try {
                let results = await this.getStateAndCityFromLatAndLng(parseFloat(lat), parseFloat(lng));
                this.filters.state = results.state;
                if (this.filters.agentId) {
                    let states = await getStateForAgent(this.filters.agentId)
                    if (!states?.result.includes(this.filters.state))
                        this.filters.state = states?.result[0];
                }
                this.filters.address = results.city;
                this.filters.lat = parseFloat(lat);
                this.filters.lng = parseFloat(lng);
                this.calculateVisibleRadius();
                let all = await this.getAllProjects(this.filters);

                if (all && all.result && all.result.length > 0) {
                    let slicedProjects = all.result.slice(0, 6);
                    this.projectsInCards = slicedProjects;
                    this.allProjects = all.result;
                    this.allProjects.length > 6 ? this.showLoadMoreButton = true : this.showLoadMoreButton = false;
                } else {
                    this.allProjects = [];
                    this.projectsInCards = [];
                    this.showLoadMoreButton = false;
                    this.clearMarkers();
                }
                await this.createMap(this.allProjects, lat, lng, zoom);

                this.isLoading = false;
            } catch (error) {
                console.error(error);
                this.isLoading = false;
            }
        },
        getStateFromLatLng({ lat, lng }) {
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.apikey}`)
                .then(response => response.json())
                .then(data => {
                    if (data.status === 'OK') {
                        const result = data.results[0];
                        const state = result.address_components.find(component =>
                            component.types.includes('administrative_area_level_1')
                        );
                        // console.log('State:', state?.long_name || 'Not Found');
                        this.states.forEach((defaultState) => {
                            if (state?.long_name === defaultState) {
                                this.filters.state = state?.long_name;
                                return state;
                            }
                        })
                        return state;
                    } else {
                        console.error('Geocoding error:', data.status, data.error_message);
                        return state;
                    }
                })
                .catch(error => console.error('Request failed:', error));

        },
        async getUserState({ lat, lng }) {
            try {
                let response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${this.apikey}`);
                response = await response.json();
                if (response.status === 'OK') {
                    const result = response.results[0];
                    const state = result.address_components.find(component =>
                        component.types.includes('administrative_area_level_1')
                    );
                    return state.long_name;
                } else {
                    console.error('Geocoding error:', response.status, response.error_message);
                    return state;
                }
            } catch (error) {
                console.error('Request failed:', error);
            }
        },
        initializeAutocomplete(inputId, updateCallback) {
            if (!window.google || !window.google.maps || !window.google.maps.places) {
                return;
            }
            const inputElement = document.getElementById(inputId);

            if (inputElement) {
                const autocomplete = new google.maps.places.Autocomplete(inputElement, {
                    componentRestrictions: { 'country': ['us'] }
                });
                autocomplete.addListener('place_changed', () => {
                    const place = autocomplete.getPlace();
                    updateCallback(place);
                });
            }
        },
        async handleAddressInput(place) {
            this.isMobileMenuVisible = false;
            this.isOpen = false;
            if (!place.geometry) {
                console.log("No details available for input: '" + place.name + "'");
                return;
            }
            this.filters.lat = place.geometry.location.lat();
            this.filters.lng = place.geometry.location.lng();
            const state = this.searchForStateAfterAddressInput(place);
            if (state != this.filters.state) {
                this.filters.state = state;
                this.filters.distance = 688504;
            } else {
                this.filters.distance = 48280;
            }
            this.filters.state = this.searchForStateAfterAddressInput(place);
            this.filters.address = this.searchForAddress(place);
            this.place = place;
            this.filters.addressType = place.types[0] == 'locality' ? 'locality' : 'addressType';
            if (this.filters.address == '') {
                this.clearSomeFilters();
                let dataLocation = await this.getLatAndLngFromState(this.filters.state);
                return await this.refreshData(dataLocation.lat, dataLocation.lng, 7);
            }
            await this.refreshData(this.filters.lat, this.filters.lng, this.initialzoom);

        },
        searchForStateAfterAddressInput(place) {
            const stateComponent = place.address_components.find(component => component.types.includes('administrative_area_level_1'));
            if (stateComponent) {
                return stateComponent.long_name;
            }
            return '';
        },
        searchForAddress(place) {
            let address = '';
            const localityComponent = place.address_components.find(component => component.types.includes('locality') || component.types.includes('colloquial_area'));
            if (localityComponent) {
                address = localityComponent.long_name;
                return address;
            }
            return '';
        },
        validateMin() {
            if (this.filters.minPrice > this.filters.maxPrice) {
                this.filters.minPrice = this.filters.maxPrice;
            }
        },
        validateMax() {
            if (this.filters.maxPrice < this.filters.minPrice) {
                this.filters.maxPrice = this.filters.minPrice;
            }
        },
        selectOptionsForBedAndBath(selection) {
            if (selection.minBed == 'Studio') {
                selection.minBed = 0;
            }
            if (selection.maxBed == 'Studio') {
                selection.maxBed = 0;
            }
            this.filters.noOfBeds = selection.minBed;
            this.filters.noOfBath = selection.minBath;
            this.filters.maxNoOfBeds = selection.maxBed;
            this.filters.maxNoOfBath = selection.maxBath;
        },
        handleSelectedPropertyType(type, label) {
            if (type == 0) {
                type = '';
            }
            this.selectedLabelForPropertyType = label;
            this.filters.type = type;
            this.applyFilters();
        },
        toggleMoveInDate() {
            this.filters.isQuickMoveIn = !this.filters.isQuickMoveIn;
            this.applyFilters();
        },

        // Route Path Methods
        initializeServices() {
            this.directionsService = new google.maps.DirectionsService();
            this.directionsRenderer = new google.maps.DirectionsRenderer();
            this.directionsRenderer.setOptions({
                map: this.map,
                suppressInfoWindows: true,
                suppressPolylines: false,
                preserveViewort: false,
                suppressMarkers: true,
            });
        },
        async createMap(projects, lat, lng, zoom) {
            // console.log('map params', lat, lng, zoom)
            this.userMarker = null;
            // lat = parseFloat(lat);
            // lng = parseFloat(lng);
            const coord = new google.maps.LatLng(lat, lng)
            zoom = parseInt(zoom);
            if (this.isMobile) {
                this.map = new google.maps.Map(this.$refs.map, {
                    center: coord,
                    zoom,
                    maxZoom: 18,
                    minZoom: 5,
                    mapTypeControl: true,
                    streetViewControl: true,
                    fullscreenControl: true,
                    scaleControl: false,
                    rotateControl: false,
                    gestureHandling: 'greedy',
                    disableDefaultUI: true,
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.RIGHT_TOP,
                    },
                });
            } else {
                this.map = new google.maps.Map(this.$refs.map, {
                    center: { lat, lng },
                    zoom,
                    maxZoom: 18,
                    minZoom: 5,
                    gestureHandling: 'auto',
                    draggable: 'true',
                    mapTypeControl: true,
                    streetViewControl: true,
                    fullscreenControl: true,
                    scaleControl: true,
                    rotateControl: true,
                    disableDefaultUI: true,
                });
            }


            await this.loadMarkers(projects);
            this.addMapEventListeners();

            if (this.userLocation.lat && this.userLocation.lng && this.filters.agentId) {
                if (this.userMarker) {
                    this.userMarker.setPosition(this.userLocation);
                } else {
                    this.addUserMarker(this.userLocation);
                }
            }

        },
        async loadMarkers(projects) {
            this.markers = [];
            try {
                if (projects.result) {
                    projects = projects.result;
                }
                projects = this.slightlyChangeCoordinates2(projects)
                for (const project of projects) {
                    await this.createSingleMarker(project);
                }
                if (this.map.getZoom() < 18)
                    await this.createCluster();

            } catch (error) {
                console.error('Error while loading markers:', error);
            }
        },
        async createSingleMarker(project) {
            const marker = new google.maps.Marker({
                position: { lat: project.location.coordinates[1], lng: project.location.coordinates[0] },
                map: this.map,
                icon: {
                    url: this.generateCustomMarker(project.price, false),
                    scaledSize: new google.maps.Size(100, 40),
                }
            });

            marker.project = project;

            marker.addListener('click', async () => {

                if (this.selectedMarker && this.selectedMarker !== marker) {
                    this.selectedMarker.setIcon({
                        url: this.generateCustomMarker(this.selectedMarker.project.price, false),
                        scaledSize: new google.maps.Size(100, 40),
                    });
                }

                this.selectedMarker = marker;

                marker.setIcon({
                    url: this.generateCustomMarker(project.price, true),
                    scaledSize: new google.maps.Size(100, 40),
                });

                this.infoWindow && this.infoWindow.close();
                this.infoWindow = new google.maps.InfoWindow();
                this.isMobile = window.innerWidth < 992;

                const content = this.isMobile
                    ? await this.getInfoWindowContentMobile(project)
                    : await this.getInfoWindowContent(project);

                this.infoWindow.setContent(content);
                this.infoWindow.open(this.map, marker);
            });

            this.markers.push(marker);
        },
        generateCustomMarker(price, isSelected) {
            let formattedPrice = price ? Number(price).toLocaleString() : '-';
            const bgColor = isSelected ? "#FF6B5A" : "#FFFFFF";
            const textColor = isSelected ? "#FFFFFF" : "#FF6B5A";
            const arrowColor = isSelected ? "#FFFFFF" : "#FF6B5A";
            const dollarColor = isSelected ? "black" : "#FFFFFF";
            const borderColor = "#FF6B5A";

            // const svg2 = `
            // <svg width="120" height="50" xmlns="http://www.w3.org/2000/svg">
            //         <!-- Rectangle with rounded borders -->
            //         <rect x="0" y="0" width="120" height="50" rx="5" ry="5" fill="${bgColor}" stroke="${borderColor}" stroke-width="2"/>

            //         <svg xmlns="http://www.w3.org/2000/svg" fill="${arrowColor}" viewBox="20 -3 30 30"><path d="M9,4H15V12H19.84L12,19.84L4.16,12H9V4Z" />
            //             <svg xmlns="http://www.w3.org/2000/svg" fill="${dollarColor}" viewBox="-20 -10 80 80"><path d="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z" />
            //             </svg>
            //         </svg>

            //         <text x="45" y="30" font-family="Arial" font-size="14" fill="${textColor}" font-weight="bold">$${formattedPrice}</text>
            //     </svg>
            // `;
            const svg = `
                        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="50">
                            <!-- Background Rectangle -->
                            <rect x="0" y="0" width="100" height="40" rx="8" ry="8" fill="${bgColor}" stroke="${borderColor}" stroke-width="3"/>

                            <!-- Price Text (Properly Centered) -->
                            <text x="50" y="22" font-family="Arial" font-size="15" fill="${textColor}" font-weight="bold" text-anchor="middle" dominant-baseline="middle">
                                $${formattedPrice}
                            </text>

                            <!-- Downward Arrow -->
                            <polygon points="45,40 55,40 50,48" fill="${bgColor}" stroke="${borderColor}" stroke-width="2"/>
                        </svg>
                    `;
            return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
        },
        createCluster() {
            this.markercluster = new MarkerClusterer(this.map, this.markers, {
                imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
                imageExtension: 'png',
            });

            return this.markercluster;
        },
        destroyMapAndMarkers() {
            this.markers.forEach(marker => {
                marker.setMap(null);
            });
            this.markers = [];
            this.map = null;
        },
        async clearMarkers() {
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null);
            }
            this.markers = [];
        },
        async refreshMap() {
            this.filters = {
                ...this.filters,
                limit: 4000,
                state: this.filters.state,
                address: '',
                lat: this.initiallatitude,
                lng: this.initiallongitude,
                noOfBeds: '',
                noOfBath: '',
                maxNoOfBeds: '',
                maxNoOfBath: '',
                zipCode: '',
                addressType: '',
                distance: 688504,
                sortByName: '',
                type: '',
                minPrice: 0,
                maxPrice: 9000000,
                isQuickMoveIn: false,
                propertyType: '',
            }

            let { lat, lng } = await this.getLatAndLngFromState(this.filters.state);
            this.filters.lat = lat;
            this.filters.lng = lng;

            await this.toggleComponent();
        },
        slightlyChangeCoordinates(projects) {
            const coordMap = {}

            projects.forEach(project => {
                const coord = project.location.coordinates.join(',')
                if (!coordMap[coord])
                    coordMap[coord] = 0
                coordMap[coord]++;
            })

            const allSameCoordinates = Object.keys(coordMap).length === 1 && coordMap[Object.keys(coordMap)[0]] === projects.length

            if (allSameCoordinates) {
                let first = true
                projects.forEach((project, index) => {
                    if (first) {
                        first = false;
                    } else {
                        const [lon, lat] = project.location.coordinates;
                        const adjustment = (Math.random() * 0.001 - 0.0005);
                        const newLat = lat + adjustment;
                        project.location.coordinates = [lon, newLat];
                    }
                })
            }

            return projects
        },
        async getLatAndLngFromState(state) {
            return new Promise((resolve, reject) => {
                if (!this.geocoder) {
                    this.geocoder = new google.maps.Geocoder();
                }

                this.geocoder.geocode({ address: state }, (results, status) => {
                    if (status === 'OK') {
                        const location = results[0].geometry.location;
                        const lat = location.lat();
                        const lng = location.lng();
                        this.filters.lat = lat;
                        this.filters.lng = lng;
                        resolve({ lat, lng });
                    } else {
                        reject(`Geocoding failed with status: ${status}`);
                    }
                });
            });
        },
        addMapEventListeners() {
            // Track zoom level changes
            this.mapEventListeners['customZoom'] = this.map.addListener('zoom_changed', async () => {
                this.filterVisibleCards();
                this.updateAddressLabelWhenMapEventTriggers();
            }, { passive: true });
            this.map.addListener('dragstart', () => {
                if (this.isMobile) {
                    this.isDraggingOnMobile = true;
                }
            })
            // Track map drag
            this.mapEventListeners['customDragend'] = this.map.addListener('dragend', async () => {
                this.filterVisibleCards();
                this.updateAddressLabelWhenMapEventTriggers();
                // const result = this.getStateAndCityFromLatAndLng();

            });
            //cluster click
            this.mapEventListeners['customClick'] = this.map.addListener('clusterclick', async () => {
                if (this.preventEvent) {
                    return;
                }
                this.updateAddressLabelWhenMapEventTriggers();
                this.filterVisibleCards();
            });
        },
        calculateVisibleRadius() {
            const bounds = this.map.getBounds();
            const center = this.map.getCenter();

            const northEast = bounds.getNorthEast();
            const southWest = bounds.getSouthWest();

            const midNorth = new google.maps.LatLng(northEast.lat(), center.lng());
            const midEast = new google.maps.LatLng(center.lat(), northEast.lng());
            const midSouth = new google.maps.LatLng(southWest.lat(), center.lng());
            const midWest = new google.maps.LatLng(center.lat(), southWest.lng());

            const radius = this.calculateDistance(center, midNorth);
            this.filters.distance = radius;
            return radius;
        },
        calculateDistance(point1, point2) {
            const R = 6371000;
            const lat1 = point1.lat() * Math.PI / 180;
            const lat2 = point2.lat() * Math.PI / 180;
            const deltaLat = (point2.lat() - point1.lat()) * Math.PI / 180;
            const deltaLng = (point2.lng() - point1.lng()) * Math.PI / 180;

            const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
                Math.cos(lat1) * Math.cos(lat2) *
                Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            const distance = R * c;
            return distance;
        },
        async getInfoWindowContentMobile(project) {
            let formattedPrice = project.price ? Number(project.price).toLocaleString() : null;
            let stringPrice = formattedPrice ? `<h3 style="color: #8b8b8b; font-size: 14px; font-weight: 400">From $${formattedPrice}</h3>` : '';
            let slugTitle = project.slugTitle;
            let projectId = project.projectId || project._id;
            let isQuickMoveIn = this.checkIfIsQuickMoveIn(project);
            let labelOrange = '';
            const vetResult = await this.$refs.routePathInputsRef.isDuplicated({
                lat: parseFloat(project.location.coordinates[1]),
                lng: parseFloat(project.location.coordinates[0]),
                slugTitle
            });
            const buttonClass = vetResult.isDuplicated ? 'bi bi-trash3' : 'bi-pin-angle-fill';
            const address = project.location.location.substring(0, project.location.location.indexOf(','));

            if (isQuickMoveIn) {
                labelOrange = `
                    <div style="
                        border: 1px solid ${this.mergedButtonStyle.backgroundColor}; 
                        color: ${this.mergedButtonStyle.backgroundColor};
                        font-size: 12px;
                        padding: 3px 8px; 
                        margin-top: 5px;
                        display: inline-block; 
                        text-transform: uppercase">
                        ${this.$t('quick move-in available')}
                    </div>
                `;
            }


            let addToRouteButton = `
                <button
                    class="button-icon-container btn-route"
                    data-slugtitle="${slugTitle}" 
                    data-index="${vetResult.index}" 
                    data-context="${vetResult.context}" 
                    data-active="${vetResult.isDuplicated}" 
                    data-lat="${project.location.coordinates[1]}" 
                    data-lng="${project.location.coordinates[0]}" 
                    style="
                        color: ${this.filters.agentId ? this.mergedButtonStyle.backgroundColor : '#666666'}; 
                        background: none;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        font-size: 1.45rem;
                    ">
                    <i class="${buttonClass}"></i>
                </button>
            `;

            let openInGoogleMaps = `
                <button
                    class="button-icon-container btn-open-in-maps" 
                    data-lat="${project.location.coordinates[1]}" 
                    data-lng="${project.location.coordinates[0]}" 
                    style="
                        color: ${this.filters.agentId ? this.mergedButtonStyle.backgroundColor : '#666666'}; 
                        background: none;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        font-size: 1.25rem;
                    ">
                    <i class="bi bi-google"></i>
                </button>
            `;

            return `
                <div class="info-window-container" data-slug-title="${slugTitle}" data-project-id="${projectId}">
                    <img class="info-window-img" src="${project.picture}" alt="community img" >
                    <div style="width: 100%;height: 100%; display: flex;flex-wrap: wrap; align-content: center; justify-content: center;">
                        ${!this.filters.agentId ? '' : `
                            <div class="info-button-container" style="height:20%;">
                                ${addToRouteButton}
                                ${openInGoogleMaps}
                            </div>
                        `}
                        <div style="margin-left: 10px;padding-right: 5px;padding-left: 5px;text-align:left;width:100%;height:80%;justify-content: center;">
                            <div style="line-height: 20px; color: #ed776e; width: 100%; font-size: 16px; font-weight: 400">${project.projectType}</div>
                            <div style="line-height: 20px; width: 100%; color: #8b8b8b; font-size: 16px; font-weight: 400">${project.title}</div>
                            <div style="line-height: 20px; width: 100%; color: #8b8b8b; font-size: 16px; font-weight: 400">${project.builderName || project.builder}</div>
                            <div style="line-height: 20px; color: #ed776e; width: 100%; font-size: 16px; font-weight: 600">${address}</div>
                            <div style="gap:10px;display: flex;justify-content: left; line-height: 20px; width: 100%; color: #8b8b8b; font-size: 14px; font-weight: 400">
                                <div style="font-size: 16px; font-weight: 400">${this.$t('from')}:</div>
                                <div style="color: #ed776e;font-size: 16px; font-weight: 500"> ${project.price ? `$ ${Number(project.price).toLocaleString()}` : '$ -'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            `;
        },
        async getInfoWindowContent(project) {
            let formattedPrice = project.price ? Number(project.price).toLocaleString() : '$-';
            let stringPrice = formattedPrice ? `<h4 style="color: #8b8b8b; font-size: 16px; padding-top: 20px; font-weight: 700">${this.$t('from')}: $ ${formattedPrice}</h4>` : '';
            let slugTitle = project.slugTitle;
            let projectId = project.projectId || project._id;
            let isQuickMoveIn = this.checkIfIsQuickMoveIn(project);
            let labelOrange = '';

            const vetResult = await this.$refs.routePathInputsRef.isDuplicated({
                lat: parseFloat(project.location.coordinates[1]),
                lng: parseFloat(project.location.coordinates[0]),
                slugTitle
            });

            const buttonClass = vetResult.isDuplicated ? 'bi bi-trash3' : 'bi-pin-angle-fill';
            const buttonText = vetResult.isDuplicated ? 'Remove Waypoint' : 'Add to Route';

            if (isQuickMoveIn) {
                labelOrange = `
                                <div style="
                                    border: 1px solid ${this.mergedButtonStyle.backgroundColor}; 
                                    color: ${this.mergedButtonStyle.backgroundColor};
                                    font-size: 13px;
                                    padding: 4px 11px; 
                                    margin-top: 5px;
                                    display: inline-block; 
                                    text-transform: uppercase">
                                    ${this.$t('quick move-in available')}
                                </div>
                            `;
            } else {
                labelOrange = '';
            }

            return `
                    <div class="info-window-class" style="display: flex; width: 100%; cursor: pointer; font-family: 'GillSans-SemiBold', sans-serif; background-color: #e8e8e8" data-slug-title="${slugTitle}" data-project-id="${projectId}"> 
                        <div style="flex: 1; margin: 5px; width: 193px; background-image: url('${project.picture}'); background-position: center; background-repeat: no-repeat; background-size: cover;">
                            <!--<img src="${project.picture}" alt="${project.title}" style="width: 100%; height: auto;">-->
                        </div>
                        <div style="flex: 1; padding: 5px; text-align: center;">
                            <p style="color: ${this.mergedButtonStyle.backgroundColor}; font-size: 15px;">${project.projectType}</p> 
                            <div style="color: #8b8b8b; font-size: 18px; font-weight: 600">${project.title}</div> 
                            <span style="color: #8b8b8b; font-size: 18px; font-weight: 600">${project.builderName || project.builder}</span>
                            <p style="color: ${this.mergedButtonStyle.backgroundColor}; font-size: 20px; margin-bottom: 8px; font-weight: 600">${project.location.city}, ${project.location.state}</p> 
                            ${stringPrice}
                            <hr style="border: 1px solid #C0C0C0; margin: 16px 0;">
                            <h3 style="color: #A9A9A9; font-size: 15px;">${this.$t(project.status)}</h3>
                            ${labelOrange}
                        </div>
                    </div>
                `;
        },
        clearStateAndAddress() {
            this.filters.state = '';
            this.filters.address = '';
        },
        clearBathBedAndPrices() {
            this.filters.noOfBeds = '';
            this.filters.noOfBath = '';
            this.filters.maxNoOfBeds = '';
            this.filters.maxNoOfBath = '';
            this.filters.minPrice = 0;
            this.filters.maxPrice = 9000000;
        },
        async getStateAndCityFromLatAndLng(lat, lng) {
            return new Promise((resolve, reject) => {
                if (!this.geocoder) {
                    this.geocoder = new google.maps.Geocoder();
                }

                this.geocoder.geocode({ location: { lat, lng } }, (results, status) => {
                    if (status === 'OK') {
                        let state = '';
                        let city = '';

                        for (const result of results) {
                            const stateComponent = result.address_components.find(component => {
                                return component.types.includes('administrative_area_level_1');
                            });

                            const cityComponent = result.address_components.find(component => {
                                return component.types.includes('locality') || component.types.includes('colloquial_area');
                            });

                            if (stateComponent) {
                                state = Array.isArray(stateComponent) ? stateComponent[0].long_name || '' : stateComponent.long_name || '';
                            }

                            if (cityComponent) {
                                city = Array.isArray(cityComponent) ? cityComponent[0].long_name || '' : cityComponent.long_name || '';
                            }

                            if (state && city) {
                                resolve({ state, city });
                                this.place = { address_components: result.address_components }
                                return;
                            }
                        }
                        reject('State and/or city information not found.');
                    } else {
                        reject(`Reverse geocoding failed with status: ${status}`);
                    }
                });
            });
        },
        // handleScroll() {
        //     localStorage.setItem('scrollPosition', this.$refs.scrollContainer.scrollTop);
        // },
        async parseQs() {
            let qs = decodeURI(location.search);
            qs = qs.replace('?', '');
            if (qs == '') {
                qs = this.getItemWithExpiration('qs') || '';
            }

            for (let i = 0; i < qs.split("&").length; i++) {
                let pair = qs.split("&")[i];
                let [k, v] = pair.split('=');

                // Check if the key is a prop, and if so, don't mutate the prop directly.
                if (Object.keys(this.$props).includes(k)) {
                    // Check for each prop individually to ensure the correct value is used (from prop or query string)
                    if (k === 'agentid') {
                        // Use prop value if available, otherwise fallback to query string value
                        this.filters.agentId = this.$props.agentid || v.split('?')[0];
                    } else if (k === 'state') {
                        this.filters.state = this.$props.state || v.split('?')[0];
                        let { lat, lng } = await this.getLatAndLngFromState(this.filters.state);
                        this.filters.lat = lat;
                        this.filters.lng = lng;
                    }
                    // Add similar checks for other props here as needed
                    else {
                        // For any other prop, store it in internal data (parsedQs)
                        this.parsedQs[k] = this.$props[k] || decodeURIComponent(v);
                    }
                }

                // Handle custom button styles
                if (Object.keys(this.defaultbuttonstyle).includes(k)) {
                    this.custombuttonstyle[k] = decodeURIComponent(v);
                    this.mergedButtonStyle[k] = decodeURIComponent(v);
                }

                // Handle nested custom button styles
                if (Object.keys(this.defaultbuttonstyle).map(el => 'custombuttonstyle[' + el + ']').includes(k)) {
                    let [k0, k1] = k.split(/\[|\]/).filter(Boolean);
                    if (!this[k0]) {
                        this[k0] = {};
                    }
                    this[k0][k1] = decodeURIComponent(v);
                    this.mergedButtonStyle[k1] = decodeURIComponent(v);
                }
            }

            // Store the query string to sessionStorage or localStorage
            if (location.search.replace('?', '') != '') {
                this.setItemWithExpiration('qs', qs, 1);
            }

            // Push a new state to the browser history
            window.history.pushState({}, '', '/');
        },
        setItemWithExpiration(key, value, expirationInDays) {
            const expirationMs = expirationInDays * 24 * 60 * 60 * 1000;
            const expirationTime = new Date().getTime() + expirationMs;
            const item = {
                value: JSON.stringify(value),
                expirationTime: expirationTime
            };
            localStorage.setItem(key, JSON.stringify(item));
        },
        getItemWithExpiration(key) {
            const storedItem = localStorage.getItem(key);

            if (!storedItem) {
                return null;
            }

            const item = JSON.parse(storedItem);

            if (new Date().getTime() > item.expirationTime) {
                localStorage.removeItem(key);
                return null;
            }

            return JSON.parse(item.value);
        },
        //
        slightlyChangeCoordinates2(estates) {
            const adjustedEstates = estates.map((estate) => {
                const latOffset = (this.hashToOffset(estate._id) * 0.0005) - 0.00005;
                const lngOffset = (this.hashToOffset(estate._id + 'lng') * 0.0005) - 0.00005;
                return {
                    ...estate,
                    lat: parseFloat(estate.lat) + latOffset,
                    lng: parseFloat(estate.lng) + lngOffset,
                };
            });

            return this.spreadOverlappingCoordinates(adjustedEstates);
        },
        spreadOverlappingCoordinates(estates) {
            const MIN_DISTANCE = 0.00002;
            const groupedEstates = new Map();

            estates.forEach((estate) => {
                const key = `${estate.lat.toFixed(5)}_${estate.lng.toFixed(5)}`;
                if (!groupedEstates.has(key)) {
                    groupedEstates.set(key, []);
                }
                groupedEstates.get(key).push(estate);
            });

            groupedEstates.forEach((group) => {
                if (group.length > 1) {
                    group.forEach((estate, index) => {
                        const angle = (2 * Math.PI * index) / group.length;
                        const offsetLat = MIN_DISTANCE * Math.cos(angle);
                        const offsetLng = MIN_DISTANCE * Math.sin(angle);
                        estate.lat += offsetLat;
                        estate.lng += offsetLng;
                    });
                }
            });

            return Array.from(groupedEstates.values()).flat();
        },
        hashToOffset(str) {
            let hash = 0;
            for (let i = 0; i < str.length; i++) {
                hash = (hash << 5) - hash + str.charCodeAt(i);
                hash |= 0;
            }
            return (hash % 1000) / 1000;
        },
        toggleRotation() {
            this.isRotating = !this.isRotating;
            this.closeFilters();
        },
        getAddToRouteCardButton(project) {
            const vetResult = this.$refs.routePathInputsRef.isDuplicated({
                lat: parseFloat(project.location.coordinates[1]),
                lng: parseFloat(project.location.coordinates[0]),
                slugTitle: project.slugTitle
            });
            const buttonClass = vetResult.isDuplicated ? 'bi bi-trash3' : 'bi-pin-angle-fill';

            let addToRouteButton = `
                <button ${!this.filters.agentId ? 'disabled' : ''}
                    class="button-icon-container btn-route"
                    data-slugtitle="${project.slugTitle}" 
                    data-index="${vetResult.index}" 
                    data-context="${vetResult.context}" 
                    data-active="${vetResult.isDuplicated}" 
                    data-lat="${project.location.coordinates[1]}" 
                    data-lng="${project.location.coordinates[0]}" 
                    style="
                        color: ${this.filters.agentId ? this.mergedButtonStyle.backgroundColor : '#666666'}; 
                        background: none;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        font-size: 1.45rem;
                    ">
                    <i class="${buttonClass}"></i>
                </button>
            `;

            let openInGoogleMapsButton = `
                <button ${!this.filters.agentId ? 'disabled' : ''}
                    class="button-icon-container btn-open-in-maps" 
                    data-lat="${project.location.coordinates[1]}" 
                    data-lng="${project.location.coordinates[0]}" 
                    style="
                        color: ${this.filters.agentId ? this.mergedButtonStyle.backgroundColor : '#666666'}; 
                        background: none;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        font-size: 1.25rem;
                    ">
                    <i class="bi bi-google"></i>
                </button>
            `;

            return `
                ${addToRouteButton}
                ${openInGoogleMapsButton}
                `
        },
        getOpenInGoogleMapsCardButton() {
            let openInGoogleMaps = `
                <button ${!this.filters.agentId ? 'disabled' : ''}
                    class="button-icon-container btn-open-in-maps" 
                    data-lat="${project.location.coordinates[1]}" 
                    data-lng="${project.location.coordinates[0]}" 
                    style="
                        color: ${this.filters.agentId ? this.mergedButtonStyle.backgroundColor : '#666666'}; 
                        background: none;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        font-size: 1.25rem;
                    ">
                    <i class="bi bi-google"></i>
                </button>
            `;
        },
        handleRouteButtonClick(property) {

        },
        handleMapButtonClick(property) {

        }
    },
    filters: {
        formatPrice(value) {
            if (typeof +value !== 'number')
                return value
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD'
            })
            return formatter.format(value)
        }
    },
    computed: {
        mergedButtonStyle() {
            return { ...this.defaultbuttonstyle, ...this.custombuttonstyle };
        },
        getPriceButtonText() {
            const defaultMinPrice = 0;
            const defaultMaxPrice = 9000000;

            if (
                (this.filters.minPrice !== defaultMinPrice) ||
                (this.filters.maxPrice !== defaultMaxPrice)
            ) {
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD'
                })
                const minPriceText = this.filters.minPrice ? `${formatter.format(this.filters.minPrice)}` : '0 $';
                const maxPriceText = this.filters.maxPrice ? `${formatter.format(this.filters.maxPrice)}` : '∞ $';

                return `${minPriceText} - ${maxPriceText}`;
            } else {
                return this.$t('Price');
            }
        },
        getPropertyTypeButtonText() {
            const selectedLabel = this.selectedLabelForPropertyType;
            if (selectedLabel) {
                return `${selectedLabel}`;
            } else {
                return this.$t('Property Type');
            }
        },
        getBedBathButtonText() {
            const defaultMinBed = '';
            const defaultMaxBed = '';
            const defaultMinBath = '';
            const defaultMaxBath = '';

            if (
                (this.filters.noOfBeds !== defaultMinBed) ||
                (this.filters.noOfBath !== defaultMinBath) ||
                (this.filters.maxNoOfBeds !== defaultMaxBed) ||
                (this.filters.maxNoOfBath !== defaultMaxBath)
            ) {
                const minBedText = this.filters.noOfBeds >= 0 ? `${this.filters.noOfBeds} Beds` : '0 Beds';
                const maxBedText = this.filters.maxNoOfBeds >= 0 ? `${this.filters.maxNoOfBeds} Beds` : '∞ Beds';
                const minBathText = this.filters.noOfBath ? `${this.filters.noOfBath} Baths` : '0 Baths';
                const maxBathText = this.filters.maxNoOfBath ? `${this.filters.maxNoOfBath} Baths` : '∞ Baths';

                return `${minBedText} - ${maxBedText} / ${minBathText} - ${maxBathText}`;
            } else {
                return this.$t('Bed/Bath');
            }
        },
    },
    watch: {
        lang(newVal, oldVal) {
            this.$i18n.locale = newVal
        },
        navigationMode(newVal, oldVal) {
            if (newVal != oldVal) {
                if (newVal == true) {
                    google.maps.event.removeListener(this.mapEventListeners['customZoom']);
                    google.maps.event.removeListener(this.mapEventListeners['customDragend']);
                    google.maps.event.removeListener(this.mapEventListeners['customClick']);
                } else {
                    this.addMapEventListeners();
                }
            }
        }
    },
    created() {
        document.addEventListener('click', async (event) => {
            if (event.target.closest('.btn-route')) {
                event.stopPropagation();
                const button = event.target.closest('.btn-route');
                const isActive = button.dataset.active;
                const slugTitle = button.dataset.slugtitle;
                const coordinates = { lat: parseFloat(button.dataset.lat), lng: parseFloat(button.dataset.lng) };
                this.infoWindow.close();
                if (isActive == 'false') {
                    this.addToRoute(coordinates, slugTitle);
                } else {
                    const context = button.dataset.context;
                    const index = button.dataset.index;

                    this.$refs.routePathInputsRef.removeWaypoint(index, context);
                }
                return;
            }

            if (event.target.closest('.btn-open-in-maps')) {
                const button = event.target.closest('.btn-open-in-maps');
                event.stopPropagation();
                this.infoWindow.close();
                this.$refs.routePathInputsRef.openRouteInGoogleMaps(button.dataset);
                return;
            }

            const infoWindow = event.target.closest('.info-window-container, .info-window-class');
            if (infoWindow) {
                event.stopPropagation();
                const projectId = infoWindow.dataset.projectId;
                const slugTitle = infoWindow.dataset.slugTitle;
                this.goToDetailsPage(projectId, slugTitle);
                return;
            }
        });
    },
    async mounted() {
        this.showButtonMap = false;
        console.log("YurekaiNeoMapBaseSearch mounted!");
        await loadGoogleApi();
        this.isMobile = window.innerWidth < 992;
        this.isMapVisible = true;
        this.isCardVisible = !this.isMobile || true;
        window.addEventListener('resize', () => {
            this.isMobile = window.innerWidth < 992;
        });
        this.filters.agentId = this.agentid;
        this.$nextTick(async () => {
            this.initializeAutocomplete('address-input', this.handleAddressInput);
            await this.parseQs();
            await this.fillStateFilter();
            await this.toggleComponent();
            if (this.filters.agentId && this.isMobile) {
                await this.checkPermissionAndWatchLocation();
            } else {
                this.isGpsPositionAvaible = false;
            }
            // this.detectUserLocation();
        });
    },
    beforeDestroy() {
        window.removeEventListener('resize', () => {
            this.isMobile = window.innerWidth < 992;
        });
        if (this.watchId) {
            navigator.geolocation.clearWatch(this.watchId);
        }
    },
    updated() {
        this.$nextTick(() => {
            this.initializeAutocomplete('address-input', this.handleAddressInput);
        });

    },
    destroyed() {
        console.log("Component destroyed");
    }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@300&display=swap');

* {
    font-family: 'Figtree', sans-serif;
}

.sticky-filter {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #fefefe;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 10px 0 10px;
}

.map-container-desktop {
    width: 100%;
    height: 95%;
}

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 9999;
}

.loader-content {
    text-align: center;
}


.scroll-container {
    overflow-y: auto;
    max-height: 100%;
    height: 77vh;
    overflow-y: scroll;
    margin-top: 20px;
    margin-top: 0;
}

.scroll-container-desktop {
    overflow: hidden;
    position: relative;
}

.scroll-content {
    height: 100%;
    overflow-y: scroll;
}


.scroll-content-mobile {
    overflow-y: scroll;
    height: 100%;
    padding: 0 5vw 0 5vw;
}

.scroll-container-map-open {
    padding-bottom: 57vh;
}

.scroll-content::-webkit-scrollbar {
    width: 0;
}

.scroll-container:hover .scroll-content::-webkit-scrollbar-thumb {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
}

.search-container {
    position: relative;
}

.search-icon {
    position: absolute;
    left: 10px;

    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

.form-control {
    padding-left: 30px;
}

.clear-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-100%);
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.route-path-storage-canvas {
    margin-bottom: 11%;
    height: 600px;
}

.offcanvas-wrapper {
    height: 65vh;
}

.offcanvas-body {
    overflow-y: hidden !important;
}

.close-button-canvas {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    padding: 0;
    cursor: pointer;
}

.btn-x {
    position: relative;
}

.card {
    border: 2px solid rgba(0, 0, 0, .12);
    border-radius: 8px;
    cursor: pointer;
    transition: all linear .2s;
    height: fit-content;
    margin-bottom: 20px;
}

.card-mobile {
    border: 2px solid rgba(0, 0, 0, .12);
    border-radius: 8px;
    cursor: pointer;
    transition: all linear .2s;
    margin-bottom: 1rem;
    /* margin-top: 20px; */
}

.card:hover {
    border-color: #aaa;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, .2);
}

.card-mobile:hover {
    border-color: #aaa;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, .2);
}

.card-img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.view-details {
    background-color: #FF6C00;
    color: white;
    width: 100%;
    font-size: 13px;
    border: none;
    text-align: center;
    padding: 0;
}

.view-details-g {
    background-color: #888;
    border: #888;
    color: white;
    width: 100%;
    font-size: 13px;
    border: none;
    text-align: center;
}

.view-details:hover {
    background-color: #fb946e;
    color: white;
}

.view-details-g:hover {
    background-color: #bfb7b7;
    color: white;
}

.custom-slider {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
}

.slider {
    position: relative;
}

.form-range {
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #e9ecef;
    outline: none;
    padding: 0;
    margin: 0;
    color: #FF6C00;
}

.form-range::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 50%;
    background: #FF6C00;
    cursor: pointer;
}

#minRange {
    z-index: 1;
}

#maxRange {
    z-index: 2;
}

@media (max-width: 992px) {
    .filter-dropdown {
        width: 95vw;
    }
}

.filter-dropdown {
    position: absolute;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 10;
}

.filter-section {
    margin-bottom: 15px;
}

.filter-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.filter-options button {
    margin-right: 5px;
    margin-bottom: 5px;
}

.filter-btn {
    background-color: #f7f7f7;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    padding: 5px 10px;
}

.filter-btn.active {
    background-color: #FF6C00;
    color: white;
}

.active-movein {
    background-color: #FF6C00;
    border: 1px solid #FF6C00;
    color: white;
}

input,
button,
select {
    font-size: 0.95rem;
    border: 1px solid #888;
    padding: 2px 32px 2px 12px;
    border-radius: 6px;
    height: 40px
}

.infoWindowButton {
    border: none !important;
    padding: 0% !important;
    border-radius: 0% !important;
    height: auto !important;
    font-size: 1.2rem !important;
}

.quick-movein-label {
    border: 1px solid #FF6C00;
    color: #FF6C00;
    font-size: 13px;
    padding: 4px 11px;
    margin-top: 5px;
    display: inline-block;
    text-transform: uppercase;
}

.card-body {
    width: 100% !important;
    height: 16em;

}

.orange-neo-text {
    color: #FF6C00;
    font-size: 18px;
    font-weight: 600;
}

.selected-type {
    margin-top: 10px;
    font-weight: bold;
}

.form-select.price {
    font-size: .80rem;
}

.form-select.bedbath {
    font-size: .80rem;
}

.scrool::-webkit-scrollbar {
    display: none;
}

.sticky-filter .hamburger-btn-filters,
.hamburger-btn-canvas,
.hamburger-btn-map {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
    width: 100%;
}

.hamburger-btn-map,
.hamburger-btn-canvas,
.hamburger-btn-filters {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hamburger-btn-map i {
    position: absolute;
}

.hamburger-btn-canvas i {
    position: absolute;
}

.hamburger-btn-filters i {
    position: absolute;
}

.sticky-filter .hamburger-btn-filters:hover,
.hamburger-btn-canvas:hover,
.hamburger-btn-map:hover {
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);
}

.icon-enter-active,
.icon-leave-active {
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1), opacity 0.5s ease-in-out;
}

.icon-enter {
    transform: translateY(100%) scale(0.9);
    opacity: 0;
}

.icon-leave-to {
    transform: translateY(-100%) scale(0.9);
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s linear, transform 0.5s linear;
}

.fade-enter {
    opacity: 0;
    transform: scale(0.9);
}

.fade-leave-to {
    opacity: 0;
    transform: scale(0.9);
}

.fade-filters-enter-active,
.fade-filters-leave-active {
    transition: opacity 0.15s linear, transform 0.5s linear;
}

.fade-filters-enter {
    opacity: 0;
    transform: scale(0.9);
}

.fade-filters-leave-to {
    opacity: 0;
    transform: scale(0.9);
}

.bi-signpost-2 {
    transition: transform 0.5s ease-in-out;
    color: black;
}

.rotated {
    transform: rotate(360deg);
    color: black;
}

.rotate-enter-active,
.rotate-leave-active {
    transition: transform 0.5s ease-in-out;
}

.rotate-enter,
.rotate-leave-to {
    transform: rotate(360deg);
}

body::-webkit-scrollbar {
    display: none;
}

.map {
    width: 100%;
    height: 100%;
}

.map-container-desktop {
    width: 100%;
    height: 95%;
}

.menu-list-button {
    background: none;
    border: 1px solid #888;
}

.menu-list-icon {
    margin-left: 20px;
    font-size: 1.5rem;
}

.custom-confirm-button {
    font-size: var(--button-font-size, 16px);
    font-weight: var(--button-font-weight, bold);
}

.no-projects {
    opacity: 0;
    animation: fadeIn 1s ease forwards;
    animation-delay: 2s;
}

.joystick {
    position: relative;
    height: 50px;
    width: 50px;
    top: -15vh;
    left: 75vw;
    z-index: 5;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.joystick-outer {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.joystick-inner {
    align-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 50px;
    color: #5d5d5d;
}

.left {
    top: 15rem;
    left: 3vh;
    transform: translateY(-50%);
}

.right {
    top: 15rem;
    right: 3vh;
    transform: translateY(-50%);
}

.navbar-buttons {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.map-container {
    height: 57vh;
    flex-shrink: 0;
}

.scrollable-cards {
    flex-grow: 1;
    padding-bottom: 60px;
    box-sizing: border-box;
    margin-bottom: 0;
}

.load-more-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0 !important;
    margin-bottom: 30px;
}

.load-more-btn-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0 !important;
    margin-bottom: 40px;
}

.location-icon {
    position: relative;
    display: inline-block;
}

.location-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid #1e73be;
    display: flex;
    justify-content: center;
    align-items: center;
}

.location-circle i {
    font-size: 30px;
    color: #1e73be;
}

.card-buttons-container {
    display: flex;
    justify-content: center;
    gap: 70%;
    position: relative;
    z-index: 10;
    top: 82%
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media (min-width: 700px) and (max-width: 1570px) {
    .col-md-6-custom {
        flex: 0 0 auto;
        width: 50%;
    }
}

.icon-container {
    position: relative;
    display: inline-block;
}

.icon-container i {
    position: relative;
    z-index: 1;
}

.icon-container::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background-color: white;
    z-index: 0;
    border: 1px solid;
    padding: 10px;
    box-shadow: 5px 5px #efe4e4;
}

.route-path-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: flex-end;
    height: 85%;
    width: 100%;
}

.route-path-content {
    height: 85%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    background: white;
    z-index: 999;
}

.app-container {
    height: 100%;
    width: 100%;
    /* background-color: #fb946e; */
}

.filters {
    /* background-color: #5d5d5d; */
    width: 100%;
}

.map2 {
    /* background-color: #dcdcdc; */
    height: 85%;
    width: 100%;
}

.scrollable-container {
    width: 100%;
    /* Ensure the container takes up full width */
    max-width: 100%;
    /* Avoid overflow on the horizontal axis */
    height: 400px;
    /* Or set max-height if needed */
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
    overflow-y: auto;
    /* Enable vertical scrolling when content overflows */
    padding: 10px;
    /* Optional padding */
    box-sizing: border-box;
    /* To include padding in the element's width/height */
}

.route-storage-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    justify-content: flex-end;
    height: 85%;
    width: 100%;
}

.route-storage-content {
    height: 75%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    background: white;
    z-index: 999;
}
</style>
