const BE_BASE_URL = "https://map.newestateonly.com/neo-be-api/api/v1";
const getProjects = async (bounds, currentPage, pageSize) => {
    let params = {
        fields: {},
        projections: {
            _id: 0,
            title: 1,
            builder: 1, zone: 1,
            location: 1, picture: 1,
            homedesign: 1, level2Buildings: 1,
            level2Residences: 1, status: 1,
            type: 1,
            slugTitle: 1,
            isQuickMoveIn: 1,
        },
        pagination: {
            skip: (currentPage - 1) * pageSize
        },
    };

    let url = new URL(`${BE_BASE_URL}/projects/locations`);
    url.searchParams.append('apiKey', 'neo-map-base-search');
    url.searchParams.append('fields', JSON.stringify(params.fields));
    url.searchParams.append('projections', JSON.stringify(params.projections));
    url.searchParams.append('pagination', JSON.stringify(params.pagination));
    if (bounds) {
        url.searchParams.append('minLat', bounds.minLat);
        url.searchParams.append('minLng', bounds.minLng);
        url.searchParams.append('maxLat', bounds.maxLat);
        url.searchParams.append('maxLng', bounds.maxLng);
    }
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return await response.json();
    } catch (err) {
        console.error(err);
        return [];
    }
};
// let requestIdCounter = 0;
let controller = null;

async function getStateForAgent(agentId) {
    if (controller) {
        controller.abort();
    }
    // const requestId = ++requestIdCounter;
    // console.log("counter", requestId)
    let url = new URL(`${BE_BASE_URL}/projects/neo-states`);
    url.searchParams.append('apiKey', 'neo-map-base-search');
    url.searchParams.append('agentId', agentId);
    try {
        controller = new AbortController();
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            signal: controller.signal
        });
        controller = null
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (err) {
        if (err.name == 'AbortError') {
            //console.log('Aborted!')
            return null;
        } else {
            return null;
            // throw err;
        }
    }
}

async function getFilteredProjects(filters) {
    if (controller) {
        controller.abort();
    }
    // const requestId = ++requestIdCounter;
    // console.log("counter", requestId)
    let url = new URL(`${BE_BASE_URL}/projects/neo-locations`);
    url.searchParams.append('apiKey', 'neo-map-base-search');
    if (filters) {
        url.searchParams.append('limit', filters.limit);
        url.searchParams.append('state', filters.state);
        url.searchParams.append('zipCode', filters.zipCode);
        url.searchParams.append('addressType', filters.addressType);
        url.searchParams.append('address', filters.address);
        appendParam(url, 'noOfBeds', filters.noOfBeds, '');
        appendParam(url, 'noOfBath', filters.noOfBath, '');
        appendParam(url, 'maxNoOfBeds', filters.maxNoOfBeds, '');
        appendParam(url, 'maxNoOfBath', filters.maxNoOfBath, '');
        appendParam(url, 'minPrice', filters.minPrice, 0);
        appendParam(url, 'maxPrice', filters.maxPrice, 9000000);
        url.searchParams.append('isQuickMoveIn', filters.isQuickMoveIn);
        url.searchParams.append('lat', filters.lat);
        url.searchParams.append('lng', filters.lng);
        url.searchParams.append('distance', filters.distance);
        url.searchParams.append('type', filters.type);
        url.searchParams.append('sortByName', filters.sortByName);
        if (!(filters.agentId == null || filters.agentId == undefined || filters.agentId === '')) {
            url.searchParams.append('agentId', filters.agentId);
        }
    }
    try {
        controller = new AbortController();
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
            },
            signal: controller.signal
        });
        controller = null
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return await response.json();
    } catch (err) {
        if (err.name == 'AbortError') {
            //console.log('Aborted!')
            return null;
        } else {
            return null;
            // throw err;
        }
    }
}
function appendParam(url, paramName, value, defaultValue) {
    if (value !== undefined && value !== defaultValue) {
        url.searchParams.append(paramName, value);
    }
}


export { getProjects, getFilteredProjects, getStateForAgent };
