var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('button',{staticClass:"button-icon-container btn-route",style:({
        color: _vm.agentid ? _vm.mergedButtonStye.backgroundColor : '#666666',
        background: 'none',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        fontSize: '1.45rem'
    }),attrs:{"disabled":!_vm.agentid}},[_c('i',{class:_vm.vetResult.isDuplicated ? 'bi bi-trash3' : 'bi-pin-angle-fill'})]),_c('button',{staticClass:"button-icon-container btn-open-in-maps",style:({
            color: _vm.agentid ? _vm.mergedButtonStye.backgroundColor : '#666666',
            background: 'none',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            fontSize: '1.25rem',
        }),attrs:{"disabled":!_vm.agentid}},[_c('i',{staticClass:"bi bi-google"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }