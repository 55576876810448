<template>
    <div>
        <button :disabled="!agentid" class="button-icon-container btn-route" :style="{
            color: agentid ? mergedButtonStye.backgroundColor : '#666666',
            background: 'none',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            fontSize: '1.45rem'
        }">
            <i :class="vetResult.isDuplicated ? 'bi bi-trash3' : 'bi-pin-angle-fill'"></i>
        </button>

        <button :disabled="!agentid" class="button-icon-container btn-open-in-maps" :style="{
                color: agentid ? mergedButtonStye.backgroundColor : '#666666',
                background: 'none',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                fontSize: '1.25rem',
            }">
            <i class="bi bi-google"></i>
        </button>
    </div>
</template>

<script>


export default {
    props: {
        agentid: String,
        mergedButtonStye: Object,
        community: Object
    },
    data() {
        return {

        }
    },
    computed: {
        vetResult() {
            const vetResult = this.$parent.$refs.routePathStorageRef.isDuplicated({
                lat: parseFloat(this.community.location.coordinates[1]),
                lng: parseFloat(this.community.location.coordinates[0]),
                slugTitle: this.community.slugTitle
            });
            // console.log('vetresult', vetResult)
            return vetResult;
        }
    },
    methods: {
        handleAddToRoute() {
            const communityCoordinates = {
                lat: this.community.location.coordinates[1],
                lng: this.community.location.coordinates[0]
            }
            if (this.vetResult.isDuplicated) {
                this.$emit('add-to-route', communityCoordinates, this.community.slugTitle);
            } else {
                // this.$refs.routePathInputsRef.removeWaypoint({ context: this.vetResult.context, index: this.vetResult.index })
                this.$emit('remove-from-route', { context: this.vetResult.context, index: this.vetResult.index })
            }
        },
        handleOpenInMaps() {
            // this.$refs.routePathInputsRef.openRouteInGoogleMaps(this.community);
            this.$emit('open-in-maps', this.community);
        }
    }
}
</script>

<style></style>